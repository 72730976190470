// Import React & core libraries
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Import necessary styles and CSS
import './App.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

// Import application components
import Header from './components/Header';
import Consent from './components/Consent';
import Forms from './components/Forms';
import Authorisation from './components/Authorisation';
import Subscription from './components/Subscription';
import DisplayVideo from './components/DisplayVideo';
import FilterProduct from './components/FilterProduct';
import FilterChildAge from './components/FilterChildAge';
import Overview from './components/Overview';
import ProductMatchFalse from './components/ProductMatchFalse';
import ProductMatchTrue from './components/ProductMatchTrue';
import VisualConfirmation from './components/VisualConfirmation';
import HairSegmentation from './components/HairSegmentation';
import NoPage from './components/NoPage';
import YesPage from './components/YesPage';
import TellMeMorePage from './components/TellMeMorePage';
import RatingsPage from './components/RatingsPage';
import StoreSelector from './components/StoreSelector';

function App() {
  const numberOfSteps = 6; // Total number of steps in the multi-step process
  const [step, setStep] = useState(1); // Manages the current step of the process
  const [disableHeader, setDisableHeader] = useState(false); // Controls header visibility

  // Define all possible app states/pages
  const state = {
    Consent: "consent",
    Subscription: "subscription",
    Forms: "forms",
    Authorisation: "authorisation",
    DisplayVideo: "displayvideo",
    FilterChildAge: "filterchildage",
    FilterProduct: "filterproduct",
    Overview: "overview",
    ProductMatchTrue: "productmatchtrue",
    ProductMatchFalse: "productmatchfalse",
    VisualConfirmation: "visualconfirmation",
    HairSegmentation: "hairsegmentation",
    NoPage: "nopage",
    YesPage: "yespage",
    TellMeMorePage: "tellmemorepage",
    RatingsPage: "ratingspage",
    StoreSelector: "storeselector"
  };

  const [appstate, setAppState] = useState(state.Authorisation); // Initialize app state

  // Manage header visibility based on app state
  useEffect(() => {
    setDisableHeader(appstate === state.Authorisation);
  }, [appstate]);

  const navigate = useNavigate();  // Hook for programmatic navigation
  const location = useLocation(); // Get the current route location

  // Track page views for analytics
  useEffect(() => {
    window.dataLayer.push({
      event: 'pageview',
      pagePath: window.location.pathname,
    });
  }, []);

  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'pageview', { page_path: appstate });
    }
    window.dataLayer.push({
      event: 'pageview',
      pagePath: appstate,
    });
  }, [appstate]);

  // Navigation functions for managing steps
  const goToStep = (newStep) => setStep(newStep);
  const goToNextStep = () => step < numberOfSteps && setStep(step + 1);
  const goToPrevStep = () => step > 1 && setStep(step - 1);

  // Functions to navigate to specific app states
  const goToConsent = () => setAppState(state.Consent);
  const goToForms = (stepnum) => { setStep(stepnum); setAppState(state.Forms); };
  const goToAuthorisation = () => setAppState(state.Authorisation);
  const goToSubscription = () => setAppState(state.Subscription);
  const goToDisplayVideo = () => setAppState(state.DisplayVideo);
  const goToFilterChildAge = () => setAppState(state.FilterChildAge);
  const goToFilterProduct = () => setAppState(state.FilterProduct);
  const goToOverview = () => setAppState(state.Overview);
  const goToProductMatchFalse = () => setAppState(state.ProductMatchFalse);
  const goToProductMatchTrue = () => setAppState(state.ProductMatchTrue);
  const goToVisualConfirmation = () => setAppState(state.VisualConfirmation);
  const goToHairSegmentation = () => setAppState(state.HairSegmentation);
  const goToNoPage = () => setAppState(state.NoPage);
  const goToYesPage = () => setAppState(state.YesPage);
  const goToTellMeMore = () => setAppState(state.TellMeMorePage);
  const goToRatingsPage = () => setAppState(state.RatingsPage);
  const goToStoreSelector = () => setAppState(state.StoreSelector);

  return (
    <div className="App">
      {/* Header component with conditional disabling */}
      <Header goToDisplayVideo={goToDisplayVideo} disableHeader={disableHeader} goToAuthorisation={goToAuthorisation}/>
      
      {/* Conditional rendering of components based on app state */}
      {appstate === state.Authorisation && <Authorisation goToStoreSelector={goToStoreSelector} goToSubscription={goToSubscription}/>}
      {appstate === state.Subscription && <Subscription goToStoreSelector={goToStoreSelector} goToAuthorisation={goToAuthorisation} />}
      {appstate === state.StoreSelector && <StoreSelector goToDisplayVideo={goToDisplayVideo} goToAuthorisation={goToAuthorisation} />}
      {appstate === state.DisplayVideo && <DisplayVideo goToFilterProduct={goToFilterProduct} />}
      {/* {appstate === state.Consent && <Consent goToFilterProduct={goToFilterProduct} />} */}
      {appstate === state.FilterProduct && <FilterProduct goToDisplayVideo={goToDisplayVideo} goToFilterChildAge={goToFilterChildAge} />}
      {appstate === state.FilterChildAge && <FilterChildAge goToFilterProduct={goToFilterProduct} goToForms={goToForms} />}
      {appstate === state.Forms && (
        <div className="forms-wrapper">
          <Forms step={step} goToNextStep={goToNextStep} goToPrevStep={goToPrevStep} goToFilterChildAge={goToFilterChildAge} goToVisualConfirmation={goToVisualConfirmation}/>
        </div>
      )}
      {appstate === state.VisualConfirmation && <VisualConfirmation goToOverview={goToOverview} goToHairSegmentation={goToHairSegmentation} goToForms={goToForms} />}
      {appstate === state.HairSegmentation && <HairSegmentation goToVisualConfirmation={goToVisualConfirmation} goToOverview={goToOverview}  />}
      {appstate === state.Overview && <Overview goToHairSegmentation={goToHairSegmentation} goToProductMatchFalse={goToProductMatchFalse} goToProductMatchTrue={goToProductMatchTrue} goToForms={goToForms} />}
      {appstate === state.ProductMatchFalse && <ProductMatchFalse goToDisplayVideo={goToDisplayVideo}/>}
      {appstate === state.ProductMatchTrue && <ProductMatchTrue goToNoPage={goToNoPage} goToTellMeMore={goToTellMeMore} goToRatingsPage={goToRatingsPage} />}
      {appstate === state.NoPage && <NoPage goToRatingsPage={goToRatingsPage} />}
      {appstate === state.YesPage && <YesPage goToRatingsPage={goToRatingsPage} />}
      {appstate === state.TellMeMorePage && <TellMeMorePage goToNoPage={goToNoPage} goToProductMatchTrue={goToProductMatchTrue} goToRatingsPage={goToRatingsPage} />}
      {appstate === state.RatingsPage && <RatingsPage goToDisplayVideo={goToDisplayVideo} goToAuthorisation={goToAuthorisation} />}
    </div>
  );
}

export default App;