// Import Third-party libraries
import axios from 'axios';

// Function to query agent's approved email ID
export const queryAgentEmail = (email_id, environment, is_trial, dispatch) => {
    let data = { email_id, env: environment, is_trial: is_trial };  
    return new Promise((resolve, reject) => {
        axios
            .post("https://hal-cos-qua-gwc-fnapp-v1.azurewebsites.net/api/verifyemailid", data)
            .then((response) => {
                let res = response.data;            
                if (res.status) {
                    // Step 1: Extract Distributor details
                    dispatch({ type: 'DISTRIBUTOR_ID', payload: res['distributor_id'] });
                    resolve(res);  //  Resolve on success
                } else {
                    reject(new Error("Verification failed")); // Reject in "else" case
                }
            })
            .catch((err) => {
                reject(err);  // Reject on API failure
            });
    });
};