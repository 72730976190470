// Import React & core libraries
import React, { useState, useEffect } from 'react';

// Import Third-party libraries
import { useGoogleLogin } from '@react-oauth/google';
import { useDispatch } from 'react-redux'; // Import useDispatch hook
import axios from 'axios';

// Import necessary styles and CSS
import googleLoginImage from '../../assets/logos/google_login_button.png';
import '../../App.css';

// Import Local components & utilities
import AlertMessage from '../AlertMessage';


const GoogleAuth = ({ isPlatformAuthSuccess, isPlatformAuthFailure, isConsentChecked, consentMessage}) => {
    const [googleUser, setGoogleUser] = useState(null); // 🛠️ Changed from [] to null
    const dispatch = useDispatch();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showConsentAlert, setShowConsentAlert] = useState(false);

    // Google Login Handler
    const googleLogin = useGoogleLogin({
        onSuccess: (codeResponse) => {
            setGoogleUser(codeResponse);
        },
        onError: (error) => {
            isPlatformAuthFailure();
        },
        select_account: 'true'
    });

    useEffect(() => {
        if (isConsentChecked && googleUser?.access_token) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${googleUser.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    setIsLoggedIn(true);
                    const { id, name, email, picture } = res.data;
                    const agentData = {
                        platform: "Google",
                        platformID: id,
                        agentFullName: name,
                        agentEmail: email,
                        agentProfilePicture: picture
                      }
                    dispatch({ type: 'SET_AGENT_DATA', payload: agentData });
                    dispatch({ type: 'LOGIN_STATUS', payload: true }); // Changed to true
                    isPlatformAuthSuccess(agentData);
                })
                .catch((err) => {
                    isPlatformAuthFailure();
                });
        }
    }, [googleUser, isConsentChecked, dispatch]); // Added isConsentChecked to dependencies

    return (
        <div>
            <button
                className="google-login-button"
                onClick={() => {
                    if (isConsentChecked) {
                        googleLogin();
                    } else {
                        setShowConsentAlert(true);
                    }
                }}
            >
                <img className="google-login-image" src={googleLoginImage} alt="Sign In With Google" />
            </button>

            {showConsentAlert && (
                <AlertMessage
                    message={consentMessage}
                    onConfirm={() => setShowConsentAlert(false)}
                />
            )}
        </div>
    );
};

export default GoogleAuth;