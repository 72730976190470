// Import Third-party libraries
import axios from 'axios';
import { getDistributorData } from './GetDistributor';

// Function to query agent's approved email ID
export const addAgent = (agentData, environment, is_trial, dispatch) => {
    let data = { agentData, env: environment, is_trial: is_trial };

    return new Promise((resolve, reject) => {
        axios
            .post("https://hal-cos-qua-gwc-fnapp-v1.azurewebsites.net/api/addagent", data)
            .then(async (response) => {  // Using async to handle sequential actions
                let res = response.data;

                if (res.success === "success") {
                    dispatch({ type: "SET_AGENT_DATA", payload: res.agentData });

                    try {
                        if (res.agentData?.DistributorID) {
                            await getDistributorData(res.agentData.DistributorID, environment, is_trial, dispatch);
                        } else {
                            console.warn("DistributorID missing in agentData");
                        }
                    } catch (error) {
                        console.error("Error fetching distributor data:", error);
                    }

                    resolve(res); // Resolve after all actions complete
                } else {
                    reject(new Error("Verification failed"));
                }
            })
            .catch((err) => {
                reject(err); // Reject on API failure
            });
    });
};
