import React, { useState } from 'react';
import '../App.css';
import { useDispatch, useSelector } from 'react-redux';
import { toUnicodeVariant } from '../utils';
import AlertMessage from './AlertMessage';
import axios from 'axios';
import { clearHairMask} from '../store';

const renderLoadingSpinner = () => {
  return (
    <div className="loading-spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
};

const Overview = ({ goToHairSegmentation, goToProductMatchFalse, goToProductMatchTrue, goToForms }) => {
  const dispatch = useDispatch(); // Initialize useDispatch hook
  const [successfulValidation, setSuccessfulValidation] = useState(false);
  const [showAlert, setShowAlert] = useState(false); // State to control the visibility of the alert
  const [showDataAlert, setShowDataAlert] = useState(false); // State for data confirmation alert
  const [apiCallInProgress, setApiCallInProgress] = useState(false); // State to track API call progress
  const selectedCategory = useSelector(state => state.selectedCategory);
  const hair_class = useSelector(state => state.hair_class);
  const hair_state = useSelector(state => state.hair_state);
  const hair_goals = useSelector(state => state.hair_goals);
  const hair_challenges = useSelector(state => state.hair_challenges);
  const scalp_challenges = useSelector(state => state.scalp_challenges);
  const contraindications = useSelector(state => state.contraindications);
  const realHairSegment = useSelector(state => state.realHairSegment);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const store_id = useSelector(state => state.store_id);
  const enableHairSegmentation = useSelector(state => state.enableHairSegmentation);
  const environment  = process.env.REACT_APP_ENV
  const is_trial = process.env.REACT_APP_TRIAL_STATUS;
  const language = useSelector((state) => state.language);
  const translations = useSelector((state) => state.translationsData);

  const getTranslationText = (keyPath, data = translations, lang = language.toUpperCase()) => {
    const keys = keyPath.split('.');
    let result = data;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (Array.isArray(result)) {
        const found = result.find((item) => item.label === key);
        if (!found) {
          return undefined;
        }
        result = found;
      } else if (result[key] === undefined) {
        return undefined;
      } else {
        result = result[key];
      }

    }
    if (typeof result === 'object' && !Array.isArray(result) && result[lang] !== undefined) {
      return result[lang];
    }
    return result;
  };

  const product_overview = getTranslationText("Overview.questions.product_overview.question");
  const product_question = getTranslationText("Overview.questions.confirm.question");
  const confirmButton = getTranslationText("Overview.confirm.button.name");
  const errorMessage = getTranslationText("Overview.errors.warning.text");
  const confirmAlert = getTranslationText("Overview.confirmationAlert.alert.text") || "Are you sure you want to go back?";
  const confirmAlertText = getTranslationText("Overview.confirmationAlert.alert.subtext") || "Your progress will be lost.";


  const handleSubmit = () => {
    setSuccessfulValidation(true);
    setShowDataAlert(true);
    setApiCallInProgress(true);
    console.log('process begins now');
    const answers = [[hair_class], [hair_state], hair_goals, hair_challenges, scalp_challenges];
    const c = [[contraindications['age']], [contraindications['allergy']], [contraindications['pregnancy']]];
    const constraints = [[contraindications['location']], [contraindications['gender']], [contraindications['age']], [contraindications['allergy']], [contraindications['pregnancy']]];
    dispatch({ type: 'SAVED_FORM_ANSWERS', payload:answers});
    dispatch({ type: 'SAVED_CONSTRAINTS', payload:constraints});

    let data =
    {language: language,
      answers: answers,
      contraindications: c,
      num_recommendations:3,
      encoded_category:selectedCategory,
      hair_mask: realHairSegment,
      store_id: store_id, env: environment, is_trial: is_trial
    };
    console.log("data for recommendations", data);
    axios
      .post("https://hal-cos-qua-gwc-fnapp-v1.azurewebsites.net/api/createproductrecommendation", (data),
      {headers: {
        'Content-Type': 'application/json; charset=utf-8' }})
      .then((data) => {
          let resp = data.data;
          setApiCallInProgress(false);
            // Check if `price_filtered` exists and its length is greater than 0
          if (resp['price_filtered'] && resp['price_filtered'].length > 0) {
            dispatch({ type: 'SET_PRODUCT_RECOMMENDATIONS', payload:resp});
            generateDiagnosticPrompt();
            setApiCallInProgress(false);
            dispatch(clearHairMask());
            
          } else {
            setApiCallInProgress(false);
            goToProductMatchFalse(); // Assuming this navigates to failure page
            dispatch(clearHairMask());
            }
          })
          .catch((error) => {
              setApiCallInProgress(false); // Stop spinner
              alert("Une erreur est survenue lors de l'envoi de vos données. Veuillez réessayer plus tard.");
          });
      };

  const generateDiagnosticPrompt = () => {
    setShowDataAlert(true);
    console.log('process begins now for prompt');
    const answers = [[hair_class], [hair_state], hair_goals, hair_challenges, scalp_challenges];
    const c = [[contraindications['location']], [contraindications['gender']], [contraindications['age']], [contraindications['allergy']], [contraindications['pregnancy']]];

    let data =
    {language: language,
      answers: answers,
      contraindications: c,
      encoded_category:selectedCategory, env: environment, is_trial: is_trial};
    console.log('data is:', data);
    axios
      .post("https://hal-cos-qua-gwc-fnapp-v1.azurewebsites.net/api/creatediagnosticprompt", (data),
      {headers: {
        'Content-Type': 'application/json; charset=utf-8' }})
      .then((data) => {
          let resp = data.data;
          setApiCallInProgress(false);
            // Check if `price_filtered` exists and its length is greater than 0
          if (resp['llm_output']) {
            dispatch({ type: 'DIAGNOSTICS_LLM_TEXT', payload:resp['llm_output']});
            goToProductMatchTrue(); // Assuming this navigates to success page
          } else {
            goToProductMatchTrue(); // Assuming this navigates to failure page
            }
          })
          .catch((error) => {
              setApiCallInProgress(false); // Stop spinner
              alert("Une erreur est survenue lors de l'envoi de vos données. Veuillez réessayer plus tard.");
          });
      };

  // Navigate to the previous page
  const handlePrevClick = () => {
    setShowAlert(true);
  };

  // Navigate to the next page
  const handleNextClick = () => {
    if (successfulValidation) {
      dispatch(clearHairMask());
      goToProductMatchFalse();
    } else {
      setShowErrorAlert(true);
    }
    setSuccessfulValidation(false);
  };

  const answers = [
    hair_class,
    hair_state,
    hair_goals,
    hair_challenges,
    scalp_challenges,
  ];

  return (
    <div className="container">
      <div className="form" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h2 style= {{fontSize: '2rem'}}>{product_question}</h2>

        {/* Updated questionnaire layout */}
        <div className="questionnaire-section" style={{ display: 'flex', flexDirection: 'column', marginTop: '2rem', width: '90%' }}>
          {product_overview.map((question, index) => (
            <div key={index} className="question-answer-block" style={{ marginBottom: '5%', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              {/* Question */}
              <div className="question-item" style={{ flex: 1, color: 'black', fontWeight: 'bold' }}>
                {question}
              </div>
              {/* Corresponding Answer */}
              <div className="answer-item" style={{ flex: 1, color: '#d49292', fontWeight: 'bold', textAlign: 'left', marginLeft: '5%' }}>
                {Array.isArray(answers[index]) ? answers[index].join(', ') : answers[index] || 'No response available'}
              </div>
            </div>
          ))}
        </div>
        {/* Display realHairSegment image */}
        {realHairSegment && (
          <div className="image-display" style={{ marginTop: '10%', minHeight: '150px', minWidth: '150px' }}>
            <img src={realHairSegment} alt="Segmented Hair" style={{ width: '100%', height: 'auto', maxWidth: '100%' }} />
          </div>
        )}
        {apiCallInProgress && renderLoadingSpinner()}
        <div className='submit-container'>
          <div className="white-banner">
            <button className="submit-button-fixed" onClick={handleSubmit} style={{ fontSize: 'clamp(1rem, 3.2vw, 2rem)', bottom: '10%' }}> {confirmButton} </button>
          </div>
        </div>
        {showAlert && (
          <AlertMessage
          message={confirmAlert}
          subtext={confirmAlertText}
          onConfirm={() =>
              {
                setShowAlert(false);
                dispatch(clearHairMask());
                if (enableHairSegmentation){
                goToHairSegmentation();
                }
                else {
                  goToForms(6);
                };
              }
              }
              onCancel={() => {
                setShowAlert(false);
            }}
          />
        )}
      {showErrorAlert && (
        <AlertMessage
          message={
            errorMessage[0] + toUnicodeVariant(confirmButton, 'bold sans', 'bold') + errorMessage[1]
          }
          // subtext="Retourner à la page précédente entraînera la perte de toutes les informations encodées."
          onConfirm={() => {
            setShowErrorAlert(false);
          }}
        />
      )}
        <div className="footer hide-on-print">
          <button onClick={handlePrevClick}>
            <span className="symbol">&#60;</span>
          </button>
          <button onClick={handleNextClick}>
            <span className="symbol">&#62;</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Overview;