import React, { useState } from 'react';
import '../App.css';
import { useDispatch, useSelector } from 'react-redux';
import AlertMessage from './AlertMessage';

const FilterProduct = ({ goToDisplayVideo, goToFilterChildAge }) => {
  const [selectedButton, setSelectedButton] = useState('');  // Only one button should be selected
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const categories = useSelector(state => state.categories);

  const language = useSelector(state => state.language); // Get language from Redux store
  const translations = useSelector((state) => state.translationsData) || {};
  const getTranslationText = (keyPath, data = translations, lang = language.toUpperCase()) => {
    const keys = keyPath.split('.'); // Split the keyPath into an array of keys

    // Traverse the data object using the keys
    let result = data;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      // Handle arrays (e.g., intro or questions)
      if (Array.isArray(result)) {
        const found = result.find((item) => item.label === key);
        if (!found) {
          return undefined; // Return undefined if the label is not found
        }
        result = found;
      } else if (result[key] === undefined) {
        return undefined; // Return undefined if the path is not found
      } else {
        result = result[key];
      }
    }

    // If the result is an object with language keys, return the value for the specified language
    if (typeof result === 'object' && !Array.isArray(result) && result[lang] !== undefined) {
      return result[lang];
    }

    // Otherwise, return the result as is
    return result;
  };

  const hair_type = getTranslationText("filterProduct.questions.hair_type.question");
  const termsAndConditionsConsent = getTranslationText("filterProduct.termsAndConditionsConsent.terms.text");
  const confirmAlert = getTranslationText("filterProduct.confirmationAlert.alert.text");
  const confirmAlertText = getTranslationText("filterProduct.confirmationAlert.alert.subtext");


  const handlePrevClick = () => {
    setShowAlert(true);
  };

  const handleNextClick = () => {
    console.log("Selected Button:", selectedButton); // Debugging

    if (selectedButton) {
      dispatch({ type: 'SELECTED_CATEGORY', payload: selectedButton });
      goToFilterChildAge();
    } else {
      setShowErrorAlert(true);
    }
  };

  const handleButtonClick = (buttonText) => {
    // Set the selected button
    setSelectedButton(buttonText);  // Replace with the new selected button
  };


  return (
    <div className="container">
      <div className="fixed-header" style={{
        position: 'fixed',
        top: '10%',
        width: '100%',
        backgroundColor: 'white',
        padding: '20px 0',
        zIndex: 1,
        textAlign: 'center',
      }}>
        <h2 style={{ position: 'sticky', backgroundColor: 'white', padding: '20px', zIndex: 1, marginTop: '10%', fontSize: 'clamp(1.5rem, 2vw, 2.5rem)', textAlign: 'center'}}> {hair_type} </h2>
      </div>
      <div className="form" style={{marginTop: '20%'}}>
        <form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {categories.map((buttonText) => (
            <button
              className="submit-button"
              key={buttonText}
              style={{
                backgroundColor: selectedButton === buttonText ? '#d49292' : 'black',
                margin: '5%',
                width: '80%',
                height: '10%',
                minWidth: '200px',
              }}
              onClick={(e) => {
                e.preventDefault();
                handleButtonClick(buttonText);
              }}
            >
              <span>{buttonText}</span>
            </button>
          ))}
        </form>
      </div>

      {showAlert && (
        <AlertMessage
          message={confirmAlert}
          subtext={confirmAlertText}
          onConfirm={() =>
          {
            setShowAlert(false);
            goToDisplayVideo();
          }
          }
          onCancel={() => {
            setShowAlert(false);
          }}
        />
      )}

      {showErrorAlert && (
        <AlertMessage
          message={termsAndConditionsConsent}
          // subtext="Retourner à la page précédente entraînera la perte de toutes les informations encodées. "
          onConfirm={() =>
          {
            setShowErrorAlert(false);
          }
          }
        />
      )}

      <div className="footer">
        <button onClick={handlePrevClick}>
          <span className="symbol">&#60;</span>
        </button>
        <button onClick={handleNextClick}>
          <span className="symbol">&#62;</span>
        </button>
      </div>
    </div>
  );
};

export default FilterProduct;