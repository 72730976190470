import React, { useState } from 'react';
import '../App.css';
import { useDispatch, useSelector } from 'react-redux';
import AlertMessage from './AlertMessage';

const FilterChildAge = ({ goToFilterProduct, goToForms }) => {
  const [isChecked, setIsChecked] = useState(null); // Changed to null to handle both yes and no
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [successfulValidation, setSuccessfulValidation] = useState(false);


  const language = useSelector(state => state.language); // Get language from Redux store
  const translations = useSelector(state => state.translationsData);
  const getTranslationText = (keyPath, data = translations, lang = language.toUpperCase()) => {
    const keys = keyPath.split('.'); // Split the keyPath into an array of keys

    // Traverse the data object using the keys
    let result = data;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      // Handle arrays (e.g., intro or questions)
      if (Array.isArray(result)) {
        const found = result.find((item) => item.label === key);
        if (!found) {
          return undefined; // Return undefined if the label is not found
        }
        result = found;
      } else if (result[key] === undefined) {
        return undefined; // Return undefined if the path is not found
      } else {
        result = result[key];
      }
    }

    // If the result is an object with language keys, return the value for the specified language
    if (typeof result === 'object' && !Array.isArray(result) && result[lang] !== undefined) {
      return result[lang];
    }

    // Otherwise, return the result as is
    return result;
  };

  const child_age = getTranslationText("filterChildAge.questions.child_age.question");
  const child_age_ans = getTranslationText("filterChildAge.questions.child_age.answers");
  const termsAndConditionsConsent = getTranslationText("filterProduct.termsAndConditionsConsent.terms.text");
  const confirmAlert = getTranslationText("filterProduct.confirmationAlert.alert.text");
  const confirmAlertText = getTranslationText("filterProduct.confirmationAlert.alert.subtext");

  const handlePrevClick = () => {
    setShowAlert(true);
  };

  const handleNextClick = () => {
    if (isChecked !== null) { // Ensure that a selection has been made
      dispatch({ type: 'SAVE_CHILD_AGE_RESPONSE', payload: isChecked });
      goToForms(1);
    } else {
      setShowErrorAlert(true);
    }
  };

  const handleYesClick = (event) => {
    event.preventDefault(); // Prevent form submission
    setIsChecked(isChecked === true ? null : true); // Toggle selection
  };

  const handleNoClick = (event) => {
    event.preventDefault(); // Prevent form submission
    setIsChecked(isChecked === false ? null : false); // Toggle selection
  };

  const handleConfirm = () => {
    setShowAlert(false);
    goToFilterProduct();
  }

  const handleCancel = () => setShowAlert(false)

  return (
    <div className="container">
      <div className="form">
      <h2 style={{ position: 'sticky', top: 0, backgroundColor: 'white', padding: '10px', zIndex: 1, fontSize: 'clamp(1.5rem, 2vw, 2.5rem)', marginTop: '10%', textAlign: 'center'}}>{child_age}</h2>
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '10%',
          }}
        >
          <button
            className="submit-button"
            onClick={handleYesClick}
            style={{
              margin: '5%',
              backgroundColor: isChecked === true ? '#d49292' : 'black', // Green if selected
              margin: '5%',
              width: '80%',
              height: '10%',
              minWidth: '200px'
            }}
          >
            <span>{child_age_ans[0]}</span>
          </button>
          <button
            className="submit-button"
            onClick={handleNoClick}
            style={{
              margin: '5%',
              backgroundColor: isChecked === false ? '#d49292' : 'black', // Green if selected
              margin: '5%',
              width: '80%',
              height: '10%',
              minWidth: '200px'
            }}
          >
            <span>{child_age_ans[1]}</span>
          </button>
        </form>
      </div>
      {showAlert && (
        <AlertMessage
          message={confirmAlert}
          subtext={confirmAlertText}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}


      {showErrorAlert && (
        <AlertMessage
          message={termsAndConditionsConsent}
           onConfirm={() =>
          {
            setShowErrorAlert(false);
          }
          }
        />
      )}

      <div className="footer">
        <button onClick={handlePrevClick}>
          <span className="symbol">&#60;</span>
        </button>
        <button onClick={handleNextClick}>
          <span className="symbol">&#62;</span>
        </button>
      </div>
    </div>
  );
};

export default FilterChildAge;