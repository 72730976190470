import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import { LanguageProvider } from "./components/LanguageProvider";
import { Provider } from 'react-redux'; // Import Provider
import store from './store';
import { BrowserRouter } from 'react-router-dom';  // Import BrowserRouter

ReactDOM.render(
  <Provider store={store}>  {/* Wrap your root component with Provider and pass the Redux store */}
    <BrowserRouter> {/* Wrap your app with BrowserRouter for routing */}
      {/* <App /> */}
      {/* <LanguageProvider> */}
      <App />
      {/* </LanguageProvider> */}
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);