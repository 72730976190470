import React, { useState } from 'react';
import '../../App.css';
import { useSelector, useDispatch } from 'react-redux';
import AlertMessage from '../AlertMessage';
import MultiStepMenu from '../MultiStepMenu';

const Contraindications = ({ goToNextStep, goToFilterChildAge }) => {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  // Default values set to 'none'
  const [selectedLocationOption, setSelectedLocationOption] = useState('none');
  const [selectedGenderOption, setSelectedGenderOption] = useState('none');
  const [selectedAgeOption, setSelectedAgeOption] = useState('none');
  const [selectedPregnancyOption, setSelectedPregnancyOption] = useState('none');
  const [selectedAllergyOption, setSelectedAllergyOption] = useState('none');

  const childage = useSelector(state => state.childage);
  const language = useSelector(state => state.language);
  const translations = useSelector(state => state.translationsData);

  
  const [formValues, setFormValues] = useState({
    location: '',
    gender: '',
    age: '',
    pregnancy: '',
    allergy: '',
  });

  const handleLocationOptionClick = (value) => setSelectedLocationOption(value);

  const getTranslationText = (keyPath, data = translations, lang = language.toUpperCase()) => {
    const keys = keyPath.split('.'); // Split the keyPath into an array of keys

    // Traverse the data object using the keys
    let result = data;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      // Handle arrays (e.g., intro or questions)
      if (Array.isArray(result)) {
        const found = result.find((item) => item.label === key);
        if (!found) {
          return undefined; // Return undefined if the label is not found
        }
        result = found;
      } else if (result[key] === undefined) {
        return undefined; // Return undefined if the path is not found
      } else {
        result = result[key];
      }
    }

    // If the result is an object with language keys, return the value for the specified language
    if (typeof result === 'object' && !Array.isArray(result) && result[lang] !== undefined) {
      return result[lang];
    }

    // Otherwise, return the result as is
    return result;
  };

  const living = getTranslationText('contraindications.questions.living.question');
  const gender = getTranslationText('contraindications.questions.gender.question');
  const age_category = getTranslationText('contraindications.questions.age_category.question');
  const allergies = getTranslationText('contraindications.questions.allergies.question');
  const pregnancy = getTranslationText('contraindications.questions.pregnancy.question');

  const living_answers = getTranslationText('contraindications.questions.living.answers');
  const gender_answers = getTranslationText('contraindications.questions.gender.answers');
  const age_category_answers = getTranslationText('contraindications.questions.age_category.answers');
  const allergies_answers = getTranslationText('contraindications.questions.allergies.answers');
  const pregnancy_answers = getTranslationText('contraindications.questions.pregnancy.answers');
  const select_option = getTranslationText('contraindications.questions.select_option.text');
  const termsAndConditionsConsent = getTranslationText("contraindications.termsAndConditionsConsent.terms.text");
  const confirmAlert = getTranslationText("contraindications.confirmationAlert.alert.text");
  const confirmAlertText = getTranslationText("contraindications.confirmationAlert.alert.subtext");

  const handleGenderOptionClick = (value) => {
    setSelectedGenderOption(value);
    
    // If gender is gender_answers[0], set pregnancy option and prevent changes
    if (value === gender_answers[0]) {
      setSelectedPregnancyOption(pregnancy_answers[1]);
    };
  };

  const handleAgeOptionClick = (value) => {
    setSelectedAgeOption(value);
    if (value === age_category_answers[0] || age_category_answers[1]) {
      setSelectedPregnancyOption(pregnancy_answers[1]);
    }
  };

  const handleNextClick = () => {
    const requiredFields = childage
      ? [selectedAgeOption, selectedAllergyOption]
      : [selectedAgeOption, selectedPregnancyOption, selectedAllergyOption];

    if (requiredFields.every(field => field !== 'none')) {
      dispatchAnswers();
      goToNextStep();
    } else {
      // setShowErrorAlert(true);
      goToNextStep();
    }
  };

  const dispatchAnswers = () => {
    dispatch({
      type: 'SAVE_CONTRAINDICATIONS',
      payload: {
        location: selectedLocationOption,
        gender: selectedGenderOption,
        age: selectedAgeOption,
        pregnancy: selectedPregnancyOption,
        allergy: selectedAllergyOption,
      },
    });
  };

  const renderDropdown = ({ question, label, stateValue, setStateValue, answers, disabled }) => (
    <div className="form-group rounded-textbox">
      <label htmlFor={label}>{question}</label>
      <div className="custom-dropdown">
        <select
          id={label}
          name={label}
          value={stateValue}
          onChange={(e) => setStateValue(e.target.value)}
          required
          disabled={disabled}
        >
          <option value="none" disabled hidden>{select_option}</option>
          {answers &&
            Object.entries(answers).map(([key, value]) => (
              <option key={key} value={value}>{value}</option>
            ))}
        </select>
      </div>
    </div>
  );

  return (
    <div className="container">
      <div className="fixed-header">
        <MultiStepMenu step={1} numberOfSteps={6} />
      </div>

      <div className="form">
          {renderDropdown({ question: living, label: 'location', stateValue: selectedLocationOption, setStateValue: setSelectedLocationOption, answers: living_answers })}
          {renderDropdown({ question: gender, label: 'gender', stateValue: selectedGenderOption, setStateValue: handleGenderOptionClick, answers: gender_answers })}
          {renderDropdown({ question: age_category, label: 'age', stateValue: selectedAgeOption, setStateValue: handleAgeOptionClick, answers: age_category_answers })}
          {renderDropdown({ question: allergies, label: 'allergy', stateValue: selectedAllergyOption, setStateValue: setSelectedAllergyOption, answers: allergies_answers })}
          {renderDropdown({ question: pregnancy, label: 'pregnancy', stateValue: selectedPregnancyOption, setStateValue: setSelectedPregnancyOption, answers: pregnancy_answers, disabled: selectedGenderOption === gender_answers[0] })}

        <div className="footer">
          <button onClick={() => setShowAlert(true)}>
            <span className="symbol">&#60;</span>
          </button>
          <button onClick={handleNextClick}>
            <span className="symbol">&#62;</span>
          </button>
        </div>
      </div>

      {showAlert && (
        <AlertMessage
          message={confirmAlert}
          subtext={confirmAlertText}
          onConfirm={() => {
            setShowAlert(false);
            dispatch({ type: 'SET_FLOW', payload: 'backward' });
            goToFilterChildAge();
          }}
          onCancel={() => setShowAlert(false)}
        />
      )}

      {showErrorAlert && (
        <AlertMessage
          message={termsAndConditionsConsent}
          onConfirm={() => setShowErrorAlert(false)}
        />
      )}

    </div>
  );
};

export default Contraindications;
