// MultiStepMenu.js

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

const MultiStepMenu = ({ step, numberOfSteps }) => {
  const language = useSelector(state => state.language); // Get language from Redux store
  const translations = useSelector(state => state.translationsData);
  const getTranslationText = (keyPath, data = translations, lang = language.toUpperCase()) => {
    const keys = keyPath.split('.'); // Split the keyPath into an array of keys

    // Traverse the data object using the keys
    let result = data;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      // Handle arrays (e.g., intro or questions)
      if (Array.isArray(result)) {
        const found = result.find((item) => item.label === key);
        if (!found) {
          return undefined; // Return undefined if the label is not found
        }
        result = found;
      } else if (result[key] === undefined) {
        return undefined; // Return undefined if the path is not found
      } else {
        result = result[key];
      }
    }

    // If the result is an object with language keys, return the value for the specified language
    if (typeof result === 'object' && !Array.isArray(result) && result[lang] !== undefined) {
      return result[lang];
    }

    // Otherwise, return the result as is
    return result;
  };

  const characteristics = getTranslationText('multiStepMenu.Menu.characteristics.text');
  const hair_goal = getTranslationText('multiStepMenu.Menu.hair_goal.text');
  const hair_class = getTranslationText('multiStepMenu.Menu.hair_class.text');
  const hair_state = getTranslationText('multiStepMenu.Menu.hair_state.text');
  const hair_challenges = getTranslationText('multiStepMenu.Menu.hair_challenges.text');
  const scalp_challenges = getTranslationText('multiStepMenu.Menu.scalp_challenges.text');
  // Object to store subtitles for each step
  const stepSubtitles = {
    1: characteristics,
    2: hair_class,
    3: hair_state,
    4: hair_goal,
    5: hair_challenges,
    6: scalp_challenges,
  };

  // Function to get subtitle based on the step number
  const getSubtitle = (step) => {
    return stepSubtitles[step] || 'Step ' + step;
  };

  return (
    <div className="">
      <div className="subtitle">{getSubtitle(step)}</div>
      <div className="multistep-menu-container">
        <div className="multi-step-menu">
          {/* Multi-step menu */}
          <ul>
            {[...Array(numberOfSteps)].map((_, index) => (
              <li key={index + 1} className={step === index + 1 ? 'active' : ''}>
                {index + 1}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MultiStepMenu;
