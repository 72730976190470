import React, { useState } from 'react';
import '../App.css';
import { useDispatch, useSelector } from 'react-redux';

const renderLoadingSpinner = () => {
  return (
    <div className="loading-spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
};

const ProductMatchFalse = ({ goToDisplayVideo }) => {
  const dispatch = useDispatch(); // Initialize useDispatch hook
  const [successfulValidation, setSuccessfulValidation] = useState(false);
  const [showAlert, setShowAlert] = useState(false); // State to control the visibility of the alert
  const [showDataAlert, setShowDataAlert] = useState(false); // State for data confirmation alert
  const [apiCallInProgress, setApiCallInProgress] = useState(false); // State to track API call progress
  const [downloadingPDF, setDownloadingPDF] = useState(false); // State to track PDF downloading
  const [enableButton, setEnableButton] = useState(false); // State to enable/disable buttons
  const language = useSelector((state) => state.language);
  const translations = useSelector((state) => state.translationsData);

  const getTranslationText = (keyPath, data = translations, lang = language.toUpperCase()) => {
    const keys = keyPath.split('.');
    let result = data;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (Array.isArray(result)) {
        const found = result.find((item) => item.label === key);
        if (!found) {
          return undefined;
        }
        result = found;
      } else if (result[key] === undefined) {
        return undefined;
      } else {
        result = result[key];
      }

    }
    if (typeof result === 'object' && !Array.isArray(result) && result[lang] !== undefined) {
      return result[lang];
    }
    return result;
  };

  const no_product = getTranslationText("productMatchFalse.messages.no_product");
  const reassurance = getTranslationText("productMatchFalse.messages.reassurance");
  const stay_tuned = getTranslationText("productMatchFalse.messages.stay_tuned");
  const retry_button = getTranslationText("productMatchFalse.buttons.retry");

  const handleSubmit = () => {
    setSuccessfulValidation(true);
    setShowDataAlert(true);
    goToDisplayVideo();
  };

  return (
    <div className="container">
      <div className="form" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {/* First h2 - stays the same */}
        <h2 style={{fontSize: '2.0rem'}}>{no_product}​​</h2>

        {/* Second h2 - less bold, spaced lower */}
        <h2 style={{ marginTop: '20%', fontWeight: 'normal', display: 'fixed' }}>
        {reassurance}
        </h2>

        {/* Third h2 - bigger, bold, brownish-pink color */}
        <h2 style={{ fontSize: '3.2rem', fontWeight: 'bold', color: '#d49292', marginTop: '20%', display: 'fixed' }}>
        {stay_tuned}
        </h2>

        {apiCallInProgress && renderLoadingSpinner()}
        <div className='submit-container'>
          <div className="white-banner">
            <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', width: '100%', marginTop: '20px'}}>
              <button onClick={handleSubmit} style={{ ...styles.button, backgroundColor: '#d49292', fontSize: '3.3vw' }}>{retry_button}</button>
            </div>
          </div>
        </div>
        <div
        className="footer hide-on-print"
        style={{
          backgroundColor: 'white',
          borderColor: 'white',
          boxShadow: 'none'
        }}
      >
      </div>
      </div>
    </div>
  );
};

// Styling using JS objects
const styles = {
  button: {
    backgroundColor: '#d49292',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '20px',
    height: '60px',
    fontWeight: 'bold',
    textAlign: 'center',
    cursor: 'pointer',
    marginTop: '-5%'
  }
};

export default ProductMatchFalse;