import React, { useState } from 'react';
import googleLogoutImage from '../assets/logos/google_logout_button.png'; 
import { googleLogout } from '@react-oauth/google';
import { logoutProfile } from '../store';
import AlertMessage from './AlertMessage';
import { useDispatch,useSelector } from 'react-redux'; // Import useDispatch hook


const Logout = ({ goToAuthorisation }) => {
  const [showAlert, setShowAlert] = useState(false);
  const agentData = useSelector(state => state.agentData);

  const handleLogoutClick = () => {
    if (agentData.platform==="Google"){
      googleLogout();
      logoutProfile();
    }
    else {
      window.FB.logout(function(response) {
        console.log("Logged out of Facebook:", response);
      });
      logoutProfile(); // Clear your Redux store
    }
    setShowAlert(true);
  };

  return (
    <div>
      {showAlert && (
      <AlertMessage
        message="Êtes-vous sûr de vouloir quitter cette page ?"
        subtext="Toute modification non enregistrée sera perdue."
        onConfirm={() => 
          {
            goToAuthorisation();
            setShowAlert(false);
          }} // Log out only if "Oui" is clicked
        onCancel={() => {
          setShowAlert(false);
      }}   // Close alert if "Non" is clicked
      />
    )}
    <div className='logout-button' onClick={handleLogoutClick}>
      <img src={agentData?.agentProfilePicture} alt="Sign Out" className='logout-image'/>
    </div>
  </div>
  );
};

export default Logout;