import React, { useState, useEffect, useRef } from "react";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import usFlag from "../assets/icons/usFlag.png";
import nlFlag from "../assets/icons/nlFlag.png";
import frFlag from "../assets/icons/frFlag.png";

const languages = [
  { code: "en", label: "English", flag: usFlag },
  { code: "nl", label: "Nederlands", flag: nlFlag },
  { code: "fr", label: "Français", flag: frFlag },
];

const LanguageSelector = () => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language); // Get language from Redux store

  const [selectedLanguage, setSelectedLanguage] = useState(language || "en"); // Default to 'en'
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = (event) => {
    event.stopPropagation();
    setDropdownOpen((prev) => !prev);
  };

  const selectLanguage = (langCode) => {
    setSelectedLanguage(langCode);
    dispatch({ type: "SET_LANGUAGE", payload: langCode }); // Update Redux store
    setDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  // Find selected language object, default to English if not found
  const selectedLang = languages.find((lang) => lang.code === selectedLanguage) || languages[0];

  return (
    <div className="language-selector" ref={dropdownRef}>
      <button className="language-button" onClick={toggleDropdown}>
        <img src={selectedLang.flag} alt={`${selectedLang.label} Flag`} className="flag-icon" />
        {selectedLang.label} ▼
      </button>

      {dropdownOpen && (
        <ul className="language-dropdown">
          {languages.map((lang) => (
            <li
              key={lang.code}
              className={selectedLanguage === lang.code ? "active" : ""}
              onClick={() => selectLanguage(lang.code)}
            >
              <img src={lang.flag} alt={`${lang.label} Flag`} className="flag-icon" />
              {lang.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageSelector;
