import React, { useState, useEffect } from 'react';
import halisiPicture from '../assets/logos/halisi_picture.png';
import { useDispatch , useSelector } from 'react-redux';
import axios from 'axios';

const DisplayVideo = ({ goToFilterProduct }) => {
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailureAlert, setShowFailureAlert] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [apiCallInProgress, setApiCallInProgress] = useState(false);
  const language = useSelector(state => state.language);
  const store_id = useSelector(state => state.store_id);
  const environment  = process.env.REACT_APP_ENV
  const is_trial = process.env.REACT_APP_TRIAL_STATUS;
  const translations = useSelector((state) => state.translationsData);

  const getTranslationText = (keyPath, data = translations, lang = language.toUpperCase()) => {
    const keys = keyPath.split('.'); // Split the keyPath into an array of keys

    // Traverse the data object using the keys
    let result = data;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      // Handle arrays (e.g., intro or questions)
      if (Array.isArray(result)) {
        const found = result.find((item) => item.label === key);
        if (!found) {
          return undefined; // Return undefined if the label is not found
        }
        result = found;
      } else if (result[key] === undefined) {
        return undefined; // Return undefined if the path is not found
      } else {
        result = result[key];
      }
    }

    // If the result is an object with language keys, return the value for the specified language
    if (typeof result === 'object' && !Array.isArray(result) && result[lang] !== undefined) {
      return result[lang];
    }

    // Otherwise, return the result as is
    return result;
  };


  const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  // **Calling Translations from displayVideo**
  const productAssistant = getTranslationText("displayVideo.productAssistant");
  const productRecommendationDisclaimer = getTranslationText("displayVideo.productRecommendationDisclaimer");
  const go = getTranslationText("displayVideo.go");

  const handleClick = async () => {
    getProductCategories();
    await sleep(1000); // Ensure sleep is defined elsewhere in your code
    goToFilterProduct();
  };

  const getProductCategories = () => {
    setApiCallInProgress(true);
    setIsSubmit(true);
    let data =
      {language: language,
        store_id: store_id.toString(), env: environment, is_trial: is_trial
      };
      axios
        .post("https://hal-cos-qua-gwc-fnapp-v1.azurewebsites.net/api/readDBData", data)
                    .then((data) => {
                        let resp = data.data;
                        dispatch({ type: 'SET_PRODUCT_CATEGORIES', payload: resp['product_categories']}); // Dispatch action to save res object 
                        dispatch({ type: 'SET_QUESTIONNAIRE', payload: resp['questionnaire']});
                        dispatch({type: 'SET_CONTRAINDICATIONS', payload: resp['contraindications']})
                        setApiCallInProgress(false);
                      }
                  )};

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      marginTop: '10%',
      padding: '0 10%', // Add padding for smaller screens
    }}>
      <div style={{
        textAlign: 'center',
        maxWidth: '80%',
      }}>
        <h2 style={{
          textAlign: 'center',
          fontSize: 'clamp(2.0rem, 2vw, 2.5rem)', // Responsive font size
          lineHeight: '1.2', // Adjust line height for better readability
          maxWidth: '600px',
          margin: '0 auto',
          padding: '0 1rem', // Optional: Add horizontal padding for smaller screens
        }}>
          {productAssistant}
        </h2>
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '5%',
      }}>
        <img
          src={halisiPicture}
          alt="Halisi Assistant"
          style={{
            maxWidth: '50%',
            height: 'auto',
            objectFit: 'contain', // Ensures the image scales properly within the container
          }}
        />
      </div>
      <p style={{
        marginTop: '1rem',
        fontSize: 'clamp(0.8rem, 1.5vw, 1rem)', // Responsive font size
        maxWidth: '80%', // Limit width for readability on larger screens
        textAlign: 'center',
      }}>
        {productRecommendationDisclaimer}
      </p>
      <div className='submit-container'>
        <div className="white-banner" style={{
          backgroundColor: 'white',
          width: '100%',
          height: '200px', // Adjust this value as needed
          display: 'flex',
          alignItems: 'center',
          bottom: '0',
          justifyContent: 'center',
        }}>
          <button className="submit-button-fixed"
            onClick={handleClick}
            style={{ backgroundColor: '#d49292', fontSize: 'clamp(1rem, 3.2vw, 2rem)' }}>
            {go}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DisplayVideo;
                