// Import Third-party libraries
import axios from 'axios';

// Function to retrieve store information
export const getDistributorData = async (distributor_id, environment, is_trial, dispatch) => {

    try {
        
        let data = {
            distributor_id: distributor_id,
            env: environment,
            is_trial: is_trial 
        };

        const response = await axios.post(
            "https://hal-cos-qua-gwc-fnapp-v1.azurewebsites.net/api/readdistributordetails",
            data
        );

        let res = response.data;
        dispatch({ type: 'DISTRIBUTOR_INFO', payload: res });

        await new Promise((resolve) => setTimeout(resolve, 200)); // Optional delay
    } catch (err) {
        console.error("Error fetching store info:", err);
        if (err.response?.status === 501 || err.response?.status === 404) {
            console.log("Store info not found.");
        }
    }
};