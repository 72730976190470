// Import React & core libraries
import React from 'react';

// Import necessary styles and CSS
import '../App.css';

/**
 * AlertMessage Component
 * 
 * A reusable alert dialog that displays a message, optional subtext, and one or two buttons.
 * 
 * Props:
 * - message (string): The main alert message.
 * - subtext (string, optional): Additional explanatory text.
 * - onConfirm (function): Function called when the confirm/OK button is clicked.
 * - onCancel (function, optional): Function called when the cancel button is clicked. If provided, displays "Yes/No" buttons instead of a single "OK" button.
 */
const AlertMessage = ({ message, subtext, onConfirm, onCancel }) => {
  return (
    // Full-screen overlay for the alert
    <div className="alert-overlay">
      {/* Alert box containing the message and buttons */}
      <div className="alert-box">
        
        {/* Main alert message */}
        <p className="alert-message">{message}</p>

        {/* Optional subtext, only shown if provided */}
        {subtext && <p className="alert-subtext">{subtext}</p>}

        {/* Divider line to separate text from buttons */}
        <div className="alert-divider"></div>

        {/* Buttons section */}
        <div className="alert-buttons">
          {onCancel ? (
            // Two-button layout when a cancel action is provided
            <>
              <button className="alert-button cancel" onClick={onCancel}>
                Non {/* "No" button */}
              </button>
              <button className="alert-button confirm" onClick={onConfirm}>
                Oui {/* "Yes" button */}
              </button>
            </>
          ) : (
            // Single "OK" button if no cancel action is provided
            <button className="alert-button ok" onClick={onConfirm}>
              OK
            </button>
          )}
        </div>

      </div>
    </div>
  );
};

export default AlertMessage;