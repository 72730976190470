// Import React & core libraries
import React, { useState, useEffect } from 'react';

// Import Third-party libraries
import FacebookLogin from 'react-facebook-login';
import { useDispatch } from 'react-redux'; // Import useDispatch hook

// Import necessary styles and CSS
import '../../App.css';

// Import Local components & utilities
import AlertMessage from '../AlertMessage';

const FacebookAuth = ({ facebookAppId, isPlatformAuthSuccess, isPlatformAuthFailure, isConsentChecked, consentMessage}) => {
    const dispatch = useDispatch();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showConsentAlert, setShowConsentAlert] = useState(false);



    const handleFacebookResponse = (response) => {
      if (response.status === 'unknown') {
          isPlatformAuthFailure();
      } 
      else {
        setIsLoggedIn(true);
        const { id, name, email, picture } = response.data;
        const agentData = {
            platform: "Facebook",
            platformID: id,
            agentFullName: name,
            agentEmail: email,
            agentProfilePicture: picture
          }
        dispatch({ type: 'SET_AGENT_DATA', payload: agentData });
        dispatch({ type: 'LOGIN_STATUS', payload: true });
        isPlatformAuthSuccess(agentData);
      }
    };

    const handleClick = (event) => {
      if (!isConsentChecked) {
          event.preventDefault(); // Stop Facebook login from triggering
          setShowConsentAlert(true);
      }
    };
  
    const handleConsentConfirm = () => {
      setShowConsentAlert(false); // Hide the consent alert when user confirms
      window.location.href = window.location.href;
    };

    return (
        <div>
        <FacebookLogin
            appId={facebookAppId} // Facebook App ID
            fields="name,email,picture"  // Fields to fetch
            callback={handleFacebookResponse} // Custom callback
            icon="fa-facebook"
            textButton="Login with Facebook"
            cssClass="facebook-login-button"
            autoLoad={true}
            onClick={handleClick} // Prevent login if consent isn't checked
        />

        {showConsentAlert && (
            <AlertMessage
                message={consentMessage}
                onConfirm={handleConsentConfirm}
            />
            )}
        </div> 
    );
};

export default FacebookAuth;