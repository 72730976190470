import React, { useState } from 'react';
import productLogo from '../assets/logos/product_logo.png'; // Import your logo file
import LogoutIcon from '../assets/icons/logout_icon.png'; // Path to your logout icon
import HelpIcon from '../assets/icons/help_icon.png'; // Path to your help icon
import Logout from './Logout';
import {GoogleOAuthProvider } from '@react-oauth/google';
import AlertMessage from './AlertMessage';
import { clearDiagnosticsLLM, clearProductLLM, clearSavedProduct, clearHairMask } from '../store';
import { useDispatch,useSelector } from 'react-redux'; // Import useDispatch hook

const Header = ({ goToDisplayVideo, disableHeader, goToAuthorisation }) => {
    const [showMenu, setShowMenu] = useState(false);
    const consent_flag = useSelector(state => state.consent_flag);
    const [showAlert, setShowAlert] = useState(false);
    const dispatch = useDispatch(); // Initialize dispatch function
    const logo = useSelector(state => state.selected_distributor_logo) || "";
    const agentData = useSelector(state => state.agentData);
    const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

    const goToHelp = () => {
        setShowMenu(false);
    };

    const handleLogoutOptionClick = () => {
      setShowMenu(false); // Close the menu after clicking on an item
      setShowAlert(true);
    };

    const handleHalisiLogoClick = () => {
      dispatch(clearProductLLM());
      dispatch(clearDiagnosticsLLM());
      dispatch(clearSavedProduct());
      dispatch(clearHairMask());
      goToDisplayVideo();
    };

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const onLogoutSuccess = () => {
      dispatch(clearProductLLM());
      dispatch(clearDiagnosticsLLM());
      dispatch(clearSavedProduct());
      dispatch(clearHairMask());
      goToAuthorisation();
    };

    if (disableHeader) {
        return null; // Return null to disable rendering of the Header component
      }

    return (
    <div className="header">
    {/* Your company logo */}
    <div
      onClick={handleHalisiLogoClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: '70px', // This will create space between halisiLogo and ADAPTABLE logo
        cursor: 'pointer',
      }}
    >
      <img
        src={`data:image/png;base64,${logo}`}
        className="adaptable-logo"
        alt=""
        style={{
          width: 'auto',
          height: 'auto',  // Sets height to 10% of the viewport height for responsiveness
        }}
      />
    </div>

    <div
      onClick={handleHalisiLogoClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        position: 'fixed', // Keep it fixed in the viewport
        left: '10px',      // Move it closer to the left side
        cursor: 'pointer',
      }}
    >
    <img
        src={productLogo}
        alt="Product Logo"
        style={{
          maxWidth: '90px',
          // height: '70px',
          width: 'min(12vw, 60px)',
          height: 'auto'
        }}
      />
    </div>

    <h1 className="app-title">Halisi Cosmetics</h1>
    {/* App title */}
    {/* Logout button */}
    <div className='header-container'>
        <div className='logout-button'>
        <GoogleOAuthProvider clientId={googleClientId}>
            <Logout goToAuthorisation={goToAuthorisation}></Logout>
        </GoogleOAuthProvider>
        </div>
        <div className="menu">
            {/* Hamburger menu icon */}
            <div className="hamburger-menu" onClick={toggleMenu}>&#9776;</div>
            {/* Dropdown menu */}
            {showMenu && (
                <div className="dropdown-menu">
                    <div className="menu-item help" onClick={goToHelp}>
                        <span>Help</span>
                        <img src={HelpIcon} alt="Help Icon" className="menu-icon" />
                    </div>
                    <div className="menu-item logout" onClick={handleLogoutOptionClick}>
                        <span>Logout</span>
                        <img src={LogoutIcon} alt="Logout Icon" className="menu-icon" />
                    </div>
                    {/* Add more menu items as needed */}
                </div>
            )}
        </div>
    </div>
    {showAlert && (
      <AlertMessage
        message="Êtes-vous sûr de vouloir quitter cette page ?"
        subtext="Toute modification non enregistrée sera perdue"
        onConfirm={() =>
          {
            dispatch(clearProductLLM());
            dispatch(clearDiagnosticsLLM());
            dispatch(clearSavedProduct());
            setShowAlert(false);
            goToAuthorisation(); // Handle logout functionality (passed as a prop)
          }}
        onCancel={() => {
          setShowAlert(false);
        }}

      />
    )}
  </div>
  );
};

export default Header;