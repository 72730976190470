import React, { useState } from 'react';
import '../../App.css';
import { useSelector, useDispatch } from 'react-redux';
import MultiStepMenu from '../MultiStepMenu';
import AlertMessage from '../AlertMessage';

const HairGoal = ({ goToNextStep, goToPrevStep }) => {
  const [selectedButtons, setSelectedButtons] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [selectionLimitAlert, setSelectionLimitAlert] = useState(false);
  const dispatch = useDispatch();
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const language = useSelector((state) => state.language);
  const translations = useSelector((state) => state.translationsData);

  const getTranslationText = (keyPath, data = translations, lang = language.toUpperCase()) => {
    const keys = keyPath.split('.');
    let result = data;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (Array.isArray(result)) {
        const found = result.find((item) => item.label === key);
        if (!found) {
          return undefined;
        }
        result = found;
      } else if (result[key] === undefined) {
        return undefined;
      } else {
        result = result[key];
      }

    }
    if (typeof result === 'object' && !Array.isArray(result) && result[lang] !== undefined) {
      return result[lang];
    }
    return result;
  };

  const hair_goal = getTranslationText("hairGoal.questions.hair_goal.question");
  // const hair_goal_answers = getTranslationText("hairGoal.questions.hair_goal.answers");
  const warning = getTranslationText("hairGoal.errors.warning.message") || "You can select up to 3 options.";
  const termsAndConditionsConsent = getTranslationText("hairGoal.termsAndConditionsConsent.terms.text") || "Please agree to the terms and conditions.";
  const confirmAlert = getTranslationText("hairGoal.confirmationAlert.alert.text") || "Are you sure you want to go back?";
  const confirmAlertText = getTranslationText("hairGoal.confirmationAlert.alert.subtext") || "Your progress will be lost.";

  const questionnaire = useSelector(state => state.questionnaire); // Get language from Redux store
  
  console.log("Translations data:", translations);
  console.log("hair_goal: ", hair_goal);

  const handlePrevClick = () => {
    setShowAlert(true);
  };

  const handleNextClick = () => {
    if (selectedButtons.length > 0) {
      dispatch({ type: 'SAVED_HAIR_GOALS', payload: selectedButtons });
      goToNextStep();
    } else {
      setShowErrorAlert(true);
    }
  };

  const handleButtonClick = (buttonText) => {
    if (selectedButtons.includes(buttonText)) {
      setSelectedButtons(selectedButtons.filter((button) => button !== buttonText));
      setSelectionLimitAlert(false);
    } else {
      if (selectedButtons.length < 3) {
        setSelectedButtons([...selectedButtons, buttonText]);
        setSelectionLimitAlert(false);
      } else {
        setSelectionLimitAlert(true);
      }
    }
  };

  return (
    <div className="container">
      <div className="fixed-header">
        <MultiStepMenu step={4} numberOfSteps={6} />
        <h2
          style={{
            position: 'sticky',
            backgroundColor: 'white',
            padding: '10px',
            zIndex: 1,
            marginTop: '10%',
            fontSize: 'clamp(1.5rem, 2vw, 2.5rem)',
            textAlign: 'center',
          }}
        >
          {hair_goal}
        </h2>
      </div>
      <div className="form" style={{ marginTop: '25%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxHeight: '500px',
            overflowY: 'auto',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          }}
        >
          {Array.isArray(questionnaire['q003']) &&
            questionnaire['q003'].map((buttonText, index) => (
              <button
                className="submit-button"
                key={index}
                style={{
                  backgroundColor: selectedButtons.includes(buttonText) ? '#d49292' : 'black',
                  margin: '5%',
                  height: '10%',
                  width: '250px',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleButtonClick(buttonText);
                }}
              >
                <span>{buttonText}</span>
              </button>
            ))}
        </div>
        {selectionLimitAlert && <p style={{ color: 'red' }}>{warning}</p>}
      </div>

      {showAlert && (
        <AlertMessage
          message={confirmAlert}
          subtext={confirmAlertText}
          onConfirm={() => {
            setShowAlert(false);
            goToPrevStep();
          }}
          onCancel={() => {
            setShowAlert(false);
          }}
        />
      )}

      {showErrorAlert && (
        <AlertMessage
          message={termsAndConditionsConsent}
          onConfirm={() => {
            setShowErrorAlert(false);
          }}
        />
      )}

      {/* Navigation buttons */}
      <div className="footer" style={{ display: 'flex', alignItems: 'center' }}>
        <button onClick={handlePrevClick}>
          <span className="symbol">&#60;</span>
        </button>
        <button onClick={handleNextClick}>
          <span className="symbol">&#62;</span>
        </button>
      </div>
    </div>
  );
};

export default HairGoal;