// Import React & core libraries
import React, { useState, useEffect } from 'react';

// Import Third-party libraries
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';

// Import necessary styles and CSS
import '../App.css';

// Import Static assets (images, logos)
import productLogo from '../assets/logos/product_logo_homepage.png';
import neotexLogo from '../assets/logos/neotex_logo.png';

// Import Local components & utilities
import GoogleAuth from './access_management/GoogleAuth';
import FacebookAuth from './access_management/FacebookAuth';
import {queryAgentEmail} from './access_management/QueryAgent';
import {getDistributorData} from './access_management/GetDistributor';
import AlertMessage from './AlertMessage';
import LanguageSelector from "./LanguageSelector";
import fetchLanguage from './FetchLanguage';


const Authorisation = ({goToStoreSelector, goToSubscription }) => {
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [apiCallInProgress, setApiCallInProgress] = useState(false);

  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const facebookAppId = process.env.REACT_APP_FACEBOOK_APP_ID;
  const environment  = process.env.REACT_APP_ENV
  const trial_end_date = process.env.REACT_APP_TRIAL_END_DATE;
  const is_trial = process.env.REACT_APP_TRIAL_STATUS;

  const dispatch = useDispatch();
  const agentData = useSelector(state => state.agentData);

  const [trialExpired, setTrialExpired] = useState(false);
  const TRIAL_END_DATE = new Date(trial_end_date); // MM/DD/YYYY format
  const currentDate = new Date();

  // ALERTS
  const [showAlert, setShowAlert] = useState(false); // State to control the visibility of the alert
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showPlatformFailureAlert, setPlatformFailureAlert] = useState(false);
  const language = useSelector(state => state.language); // Get language from Redux store
  const [translations, setTranslations] = useState(null); // Start with `null`
  const [showTrialAlert, setShowTrialAlert] = useState(false);


  // renders a spinner during API call
  const renderLoadingSpinner = () => {
    return (
      <div className="loading-spinner-container-auth">
        <div className="loading-spinner-auth"></div>
      </div>
    );
  };

  // Check if we are out of the trial period
  useEffect(() => {
    if (currentDate > TRIAL_END_DATE) {
      dispatch({type: "SET_TRIAL_EXPIRY", payload: true});
      setTrialExpired(true);
    }
  }, []);


  // Fetch translations asynchronously
  useEffect(() => {
    const loadTranslations = async () => {
      const data = await fetchLanguage("", language);
      setTranslations(data); // Update translations when fetched
      dispatch({ type: 'SET_TRANSLATIONS_DATA', payload: data });
    };
    loadTranslations();
  }, [language]);
  

  // Ensure translations are available before accessing them
  function getTranslationText(keyPath, data = translations, lang = language.toUpperCase()) {
    const keys = keyPath.split(".");
    let result = data;

    for (const key of keys) {
      result = result?.[key];

      // Handle array case
      if (Array.isArray(result)) {
        result = result[0]; // Assume first element contains text
      }
    }

    return result?.[lang] || "Text not found";
  }
  
  
  
  const productDiscoveryMessage = getTranslationText("authorization.productDiscovery.text");
  const personalizedHairRecommendationsMessage = getTranslationText("authorization.personalizedHairRecommendations.text");
  const welcomeMessage = getTranslationText("authorization.welcomeMessage.text");
  const poweredByAIText = getTranslationText("authorization.poweredByAI.text");
  const privacyPolicyConsentText = getTranslationText('authorization.privacyPolicyConsent.text');
  const privacyPolicy = getTranslationText('authorization.privacyPolicy.text');
  const homeText = getTranslationText('authorization.home.text');
  const contactText = getTranslationText('authorization.contact.text');
  const copyrightNotice = getTranslationText('authorization.copyrightNotice.text');
  const confirmationAlertText = getTranslationText('authorization.confirmationAlert.text');
  const confirmationAlertSubtext = getTranslationText('authorization.confirmationAlert.subtext');
  const loginAlertText = getTranslationText('authorization.loginAlert.text');
  const loginFailureAlertText = getTranslationText('authorization.loginFailureAlert.text');
  const authenticationSuccessAlertText = getTranslationText('authorization.authenticationSuccessAlert.text');
  const termsAndConditionsConsentText = getTranslationText('authorization.termsAndConditionsConsent.text');
  const trialExpiryAlertText = getTranslationText('authorization.trialExpiryAlert.text');


  const isPlatformAuthFailure = () => {
    // Handle the Google authentication failure response here
    setPlatformFailureAlert(true);
  };

  const isPlatformAuthSuccess = async (data) => {
    // Handle the Google authentication success response here
    // Proceed to check if user exists within Halisi Database
    await queryHalisiDB(data);
  };


  // 2-step procedure to query agent within Halisi DB 
  // and retrieve data of distributors linked with agent
  const queryHalisiDB = async (data) => {
    try {
        // Step 1: Verify agent's email
        const response = await queryAgentEmail(data.agentEmail, environment, is_trial, dispatch);

        if (trialExpired) {
          setShowTrialAlert(true); // Show trial expiry alert
          return; // Stop further authentication
          }

        // Step 2: Retrieve distributor details (store info)
        await getDistributorData(response.distributor_id, environment, is_trial, dispatch);

        setShowSuccessAlert(true);  // Show success alert
    } catch (error) {
        goToSubscription();  //  Redirect if authentication fails
    }
};

  return (
    <div className="landing">
      {/* Language Selector */}
      <div className="language-container">
        <LanguageSelector />
      </div>
      {/* Halisi product logo */}
      <div className="logo-container">
        <img src={productLogo} alt="Product Logo" className="logo" />
      </div>
      <h2>{productDiscoveryMessage}</h2>
      <p style={{marginTop:'2%'}}>{personalizedHairRecommendationsMessage}</p>
      <h2 style={{marginTop:'2%'}}>{welcomeMessage}</h2>
      {/* Consent */}
      <p style={{marginTop:'5%'}}>
        <label>
          <input
            type="checkbox"
            checked={isConsentChecked}
            onChange={() => setIsConsentChecked((prev) => !prev)}
          />
            &nbsp;
            {privacyPolicyConsentText} &nbsp;
          <a href="https://halisi.ai/halisi-cosmetics-privacy" target="_blank" rel="noopener noreferrer">
            {privacyPolicy}
          </a>
        </label>
      </p>
      {/* Google Login button */}
      <GoogleOAuthProvider clientId={googleClientId}>
        <GoogleAuth
          isPlatformAuthSuccess={isPlatformAuthSuccess}
          isPlatformAuthFailure={isPlatformAuthFailure}
          isConsentChecked={isConsentChecked}
          consentMessage={termsAndConditionsConsentText}
        />
      </GoogleOAuthProvider>

      {/* Facebook Login button */}
      <FacebookAuth
        facebookAppId={facebookAppId}
        isPlatformAuthSuccess={isPlatformAuthSuccess}
        isPlatformAuthFailure={isPlatformAuthFailure}
        isConsentChecked={isConsentChecked}
        consentMessage={termsAndConditionsConsentText}
        >        
      </FacebookAuth>

      {/* Neotex logo */}
      <p>{poweredByAIText}</p>
      <div className="neotex-logo-container">
        <img src={neotexLogo} alt="Neotex Logo" />
      </div>
      {/* Bottom-fixed section */}
      <p style={{marginTop:'2%'}}><a href="#" target="_blank" rel="noopener noreferrer">{homeText}</a> | <a href="https://halisi.ai/halisi-cosmetics-privacy" target="_blank" rel="noopener noreferrer">{privacyPolicy}</a> | <a href="https://neotex.ai/" target="_blank" rel="noopener noreferrer">{contactText}</a>​</p>
      <p style={{marginBottom:'10%'}}>{copyrightNotice}​</p>
      {/* Display spinner */}
      <div className="spinner">
        {apiCallInProgress && renderLoadingSpinner()}
      </div>

      {/* Navigation buttons */}
      <div className="footer">
        <button disabled={true}>
          <span className="symbol">&#60;</span>
        </button>
        <button disabled={true}>
          <span className="symbol">&#62;</span>
        </button>
      </div>

      {/* Alert messages */}
      {showAlert && (
        <AlertMessage
          message={confirmationAlertText}
          subtext={confirmationAlertSubtext}
          onConfirm={() => setShowAlert(false)}
          onCancel={() => setShowAlert(false)}
        />
      )}
      {showLoginAlert && (
        <AlertMessage
          message={loginAlertText}
          onConfirm={() => {
            setShowLoginAlert(false);
        }}
        />
      )}
      {showPlatformFailureAlert && (
        <AlertMessage
          message={loginFailureAlertText}
          onConfirm={() => {
            setPlatformFailureAlert(false);
        }}
        />
      )}
      {showSuccessAlert && (
        <AlertMessage
          message={authenticationSuccessAlertText}
          onConfirm={async () => {
            setShowSuccessAlert(false);
            goToStoreSelector();
          }}
        />
      )}
      {showTrialAlert && (
        <AlertMessage
          message={trialExpiryAlertText}
          onConfirm={async () => {
            setShowTrialAlert(false);
          }}
        />
      )}
    </div>
  );
};

export default Authorisation;