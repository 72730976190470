import React, { useState } from 'react';
import '../../App.css';
import { useSelector, useDispatch } from 'react-redux';
import MultiStepMenu from '../MultiStepMenu';
import AlertMessage from '../AlertMessage';

const HairState = ({ goToNextStep, goToPrevStep }) => {
  const dispatch = useDispatch();
  const [selectedButton, setSelectedButton] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [multipleSelectionAlert, setMultipleSelectionAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const questionnaire = useSelector(state => state.questionnaire); // Get language from Redux store
  
  const language = useSelector(state => state.language); // Get language from Redux store
  const translations = useSelector(state => state.translationsData);
  const getTranslationText = (keyPath, data = translations, lang = language.toUpperCase()) => {
    const keys = keyPath.split('.'); // Split the keyPath into an array of keys

    // Traverse the data object using the keys
    let result = data;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      // Handle arrays (e.g., intro or questions)
      if (Array.isArray(result)) {
        const found = result.find((item) => item.label === key);
        if (!found) {
          return undefined; // Return undefined if the label is not found
        }
        result = found;
      } else if (result[key] === undefined) {
        return undefined; // Return undefined if the path is not found
      } else {
        result = result[key];
      }
    }

    // If the result is an object with language keys, return the value for the specified language
    if (typeof result === 'object' && !Array.isArray(result) && result[lang] !== undefined) {
      return result[lang];
    }

    // Otherwise, return the result as is
    return result;
  };

  const hair_state = getTranslationText("hairState.questions.hair_state.question");
  // const hair_state_answers = getTranslationText("hairState.questions.hair_state.answers");
  const warning = getTranslationText("hairState.errors.warning.message");
  const termsAndConditionsConsent = getTranslationText("hairState.termsAndConditionsConsent.terms.text");
  const confirmAlert = getTranslationText("hairState.confirmationAlert.alert.text");
  const confirmAlertText = getTranslationText("hairState.confirmationAlert.alert.subtext");

  const handlePrevClick = () => {
    setShowAlert(true);
};

  const handleNextClick = () => {
    if (selectedButton) {
      dispatch({ type: 'SAVED_HAIR_STATE', payload: selectedButton });
      goToNextStep();
    } else {
      setShowErrorAlert(true);
    }
  };

  const handleButtonClick = (buttonText) => {
    if (selectedButton === buttonText) {
      // Désélectionner le bouton si déjà sélectionné
      setSelectedButton(null);
      setMultipleSelectionAlert(false);
    } else {
      // Sélectionner le nouveau bouton
      setSelectedButton(buttonText);
      setMultipleSelectionAlert(false);
    }
  };

  return (
    <div className="container">
      <div className="fixed-header">
        <MultiStepMenu step={3} numberOfSteps={6} />
        <h2 style={{ position: 'sticky', backgroundColor: 'white', padding: '10px', zIndex: 1, marginTop: '10%', fontSize: 'clamp(1.5rem, 2vw, 2.5rem)', textAlign: 'center'}}>
            {hair_state}
          </h2>
      </div>
      <div className="form" style={{marginTop: '25%'}}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: '500px', overflowY: 'auto',  scrollbarWidth: 'none',  msOverflowStyle: 'none'  }}>
            {questionnaire['q002'].map((item) => (
              <button
                className="submit-button"
                key={item}
                style={{
                  margin: '5%',
                  backgroundColor: selectedButton === item ? '#d49292' : 'black',
                  // width: '80%',
                  height: '10%',
                  width: '250px'
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleButtonClick(item);
                }}
              >
                <span>{item}</span>
              </button>
            ))}
          </div>
          {multipleSelectionAlert && (
            <p style={{ color: 'red' }}>
              {warning}
            </p>
          )}
        </div>

        {showAlert && (
          <AlertMessage
          message={confirmAlert}
          subtext={confirmAlertText}
          onConfirm={() =>
              {
                setShowAlert(false);
                goToPrevStep();
              }
              }
              onCancel={() => {
                setShowAlert(false);
            }}
          />
        )}

        {showErrorAlert && (
        <AlertMessage
          message={termsAndConditionsConsent}
          // subtext="Retourner à la page précédente entraînera la perte de toutes les informations encodées. "
          onConfirm={() =>
          {
            setShowErrorAlert(false);
          }
          }
        />
      )}
        {/* Navigation buttons */}
        <div className="footer" style={{ display: 'flex', alignItems: 'center' }}>
          <button onClick={handlePrevClick}>
            <span className="symbol">&#60;</span>
          </button>
          <button onClick={handleNextClick}>
            <span className="symbol">&#62;</span>
          </button>
        </div>
      </div>
    // </div>
  );
};

export default HairState;