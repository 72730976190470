import React, { useState, useRef, useEffect } from 'react';
import '../App.css';
import { useDispatch, useSelector } from 'react-redux';
import switchIcon from '../assets/icons/switch_icon.png';
import captureIcon from '../assets/icons/camera_icon.png';
import streamIcon from '../assets/icons/video_icon.png';
import Webcam from "react-webcam";
import { debounce } from 'lodash';
import { setSegmentationResponseImgSrc, setAnalyseButtonStatus } from '../store';
import { toUnicodeVariant } from '../utils';

const Camera = ({ mode }) => {
    const webcamRef = useRef(null);
    const canvasRef = useRef(null);
    const [isStreaming, setIsStreaming] = useState(false);
    const [capturedImage, setCapturedImage] = useState(null);
    const [isCaptured, setIsCaptured] = useState(false);
    const [showVideo, setShowVideo] = useState(true);
    const segmentedImage = useSelector((state) => state.segmentedImage);
    const base64Header = "data:image/jpeg;base64,";
    const dispatch = useDispatch();
    const FACING_MODE_USER = "user";
    const FACING_MODE_ENVIRONMENT = "environment";
    const moveableRectX = useRef('100');
    const moveableRectY = useRef('50');
    const moveableWidth = useRef('200');
    const moveableHeight = useRef('200');
    const [imgSrc, setImgSrc] = useState(null);
    const Konva = window.Konva;
    const isSubmit = useSelector((state) => state.isSubmit);
    const isAnalyzeDisabled = useSelector((state) => state.isAnalyzeDisabled);



    const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
    const videoConstraints = {
        width: 680,
        height: 1000,
        facingMode: FACING_MODE_USER,
    };

    useEffect(() => {
        dispatch(setSegmentationResponseImgSrc(null));
        startStream();
        setCapturedImage(null);
        setShowVideo(true);
        setIsCaptured(false);
        
    }, []);

    const switchCamera = React.useCallback(() => {
        dispatch(setSegmentationResponseImgSrc(null));
        setIsStreaming(true);
        setIsCaptured(false);
        setShowVideo(true);
        setCapturedImage(null);
        setFacingMode((prevState) =>
            prevState === FACING_MODE_USER
                ? FACING_MODE_ENVIRONMENT
                : FACING_MODE_USER
        );
    }, []);

    const captureImage = () => {
        if (isCaptured){
            alert("Vous avez déjà capturé une image. Veuillez cliquer sur le bouton " + toUnicodeVariant('𝗗𝗜𝗙𝗙𝗨𝗦𝗘𝗥', 'bold sans', 'bold') +" pour démarrer la vidéo et capturer une nouvelle image.");
        }
        else{
        dispatch(setSegmentationResponseImgSrc(null));
        setIsCaptured(true);
        setIsStreaming(false);
        setShowVideo(false);
        setCapturedImage(null);
        const video = webcamRef.current;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        // Clear the canvas before drawing the new image
         ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(video.video, 0, 0, 400, 300);
        const capturedImageDataUrl = canvas.toDataURL("image/jpeg", 1.0);
        setCapturedImage(capturedImageDataUrl);
        dispatch({ type: 'SET_CAPTURED_IMAGE', payload: capturedImageDataUrl });
        dispatch({ type: 'SET_OVERLAY_COORDINATES', payload:[100,50,200,200] });
        if (mode==='manual'){
            ctx.strokeRect(parseInt(moveableRectX.current), parseInt(moveableRectY.current), parseInt(moveableWidth.current), parseInt(moveableHeight.current));
            setImgSrc(canvas.toDataURL("image/jpeg", 1.0));
        };
        }
    };

    const startStream = () => {
        dispatch(setAnalyseButtonStatus(true));
        setIsCaptured(false);
        setIsStreaming(true);
        setShowVideo(true);
        setCapturedImage(null);
        dispatch(setAnalyseButtonStatus(false));
        const canvas = canvasRef.current;
        const video = webcamRef.current;
        dispatch({ type: 'SET_API_RESPONSE_IMG_SRC', payload:null });
        dispatch({ type: 'SET_CAPTURED_IMAGE', payload: null });
        moveableRectX.current = '100';
        moveableRectY.current = '50';
        moveableWidth.current = '200';
        moveableHeight.current = '200';
    };

    function drawFixedRect() {
        const video = webcamRef.current;
        const canvas = canvasRef.current;
        if (video && canvas) {
            const ctx = canvas.getContext('2d');
            ctx.drawImage(video.video, 0, 0, 400, 300);
            ctx.lineWidth = "3";
            ctx.strokeStyle = "green";
            ctx.strokeRect(100, 50, 200, 200);
        }
        setTimeout(drawFixedRect, 33);
    }
    setTimeout(drawFixedRect, 33);


    function addAnchor(group, x, y, name, update, restrict, drag, ownShape = "") {
        var stage = group.getStage();
        var layer = group.getLayer();
        var anchor = new Konva.Circle({
            x: x,
            y: y,
            stroke: "red",
            strokeWidth: 2,
            radius: 8,
            name: name,
            id: name,
            draggable: !drag,
            dragOnTop: true,
            dragBoundFunc: pos => {
            return restrict(pos, group, ownShape);
            }
        });
        anchor.on("mouseenter", function() {
            stage.container().style.cursor = "pointer";
        });
        anchor.on("mouseleave", function() {
            stage.container().style.cursor = "default";
        });
        anchor.on("dragmove", function(e) {
            update(this, ownShape);
            layer.draw();
        });
        anchor.on("mousedown touchstart", function() {
            group.setDraggable(drag);
        });
        anchor.on("dragend", function() {
            group.setDraggable(drag);
            layer.draw();
        });
        anchor.on("touchstart tap", function() {
            var layer = this.getLayer();
            document.body.style.cursor = "pointer";
            this.radius(40);
            layer.draw();
        });
        anchor.on("touchend", function() {
            var layer = this.getLayer();
            document.body.style.cursor = "default";
            this.radius(8);
            layer.draw();
        });
        group.add(anchor);
        stage.on("touchend", () => {
            var layer = anchor.getLayer();
            document.body.style.cursor = "default";
            anchor.radius(8);
            layer.draw();
        });
    }

    function resizeRect() {
        var stage = new Konva.Stage({
            container: "temp-container",
            width: 400,
            height: 300,
            x: 0,
            y: 0,
            visible: true
        });
        var imageObj = new Image();
        var bgimage = new Konva.Image({
            x: 0,
            y: 0,
            image: imageObj,
            width: 400,
            height: 300,
        });
        imageObj.src = capturedImage;
        var layer = new Konva.Layer();
        stage.add(layer);
        layer.add(bgimage);

        if (!segmentedImage) {
        var doubleRectGroup = new Konva.Group({
            x: x,
            y: y,
            draggable: true,
            width: 400,
            height: 400,
        });
        doubleRectGroup.on('dragstart', function () {
        });
        doubleRectGroup.on('dragend', function () {
            moveableRectX.current = this.absolutePosition().x + 100;
            moveableRectY.current = this.absolutePosition().y + 50;
        });
        layer.add(doubleRectGroup);
        var strokeColor = "green";
        var x = 100;
        var y = 50;
        var width = 400;
        var height = 400;
        const outerRectPos = 25;
        const outerWidth = width / 2;
        const outerHeight = height / 2;
        const outerX =100;
        const outerY = 50;
        var outerRect = new Konva.Rect({
            x: outerX,
            y: outerY,
            width: outerWidth,
            height: outerHeight,
            stroke: strokeColor,
            strokeWidth: 3,
            name: "outerRect",
        });
        doubleRectGroup.add(outerRect);
        addAnchor(
            doubleRectGroup,
            outerX,
            outerY,
            "topLeft",
            updateOuterRect,
            moveTopLeft,
            false
        );
        addAnchor(
            doubleRectGroup,
            outerX + outerWidth,
            outerY + outerHeight,
            "bottomRight",
            updateOuterRect,
            moveBottomRight,
            false
        );
        addAnchor(
            doubleRectGroup,
            outerX,
            outerY,
            "drag",
            updateOuterRect,
            (pos, group) => {
            return pos;
            },
            true
        );
        layer.draw();
    }
    }

    function updateOuterRect(activeAnchor) {
        var group = activeAnchor.getParent();
        var topLeft = group.find(".topLeft")[0];
        var bottomRight = group.find(".bottomRight")[0];
        var drag = group.findOne(".drag");
        var rect = group.findOne(".outerRect");
        rect.position(topLeft.position());
        var width = bottomRight.getX() - topLeft.getX();
        var height = bottomRight.getY() - topLeft.getY();
        rect.width(width);
        rect.height(height);
        moveableWidth.current = width;
        moveableHeight.current = height;

        const moveableRectCoords = [
            parseInt(moveableRectX.current),
            parseInt(moveableRectY.current),
            parseInt(moveableWidth.current),
            parseInt(moveableHeight.current)
        ];
            // Call the debounced function with the updated coordinates
        debouncedUpdateOuterRect(moveableRectCoords);
    }

    // Define a debounced version of the updateOuterRect function
    const debouncedUpdateOuterRect = debounce((moveableRectCoords) => {
        // Dispatch the debounced values to Redux store
        dispatch({ type: 'SET_MOVEABLE_RECT_COORDS', payload: moveableRectCoords });
    }, 1000000); // Adjust the debounce delay as needed (e.g., 300 milliseconds)


    function moveTopLeft(pos, group) {
        var bottomRight = group.findOne(".bottomRight");
        var absPos = bottomRight.absolutePosition();
        pos.x = pos.x > absPos.x ? absPos.x : pos.x;
        pos.y = pos.y > absPos.y ? absPos.y : pos.y;
        return pos;
    }

    function moveBottomRight(pos, group) {
        var topLeft = group.findOne(".topLeft");
        var absPos = topLeft.absolutePosition();
        pos.x = pos.x < absPos.x ? absPos.x : pos.x;
        pos.y = pos.y < absPos.y ? absPos.y : pos.y;
        return pos; 
    }


    if (mode==='automatic' && isCaptured===true && isStreaming===false){
        document.getElementById("webcam-container").style.display = "inline";
        document.getElementById("temp-container").style.display = "none";
      };

    if (mode==='manual' && isCaptured===true && isStreaming===false){
        document.getElementById("webcam-container").style.display = "none";
        document.getElementById("temp-container").style.display = "inline";
        resizeRect();
      };  
    
    if (isCaptured===false && isStreaming===true){
        document.getElementById("webcam-container").style.display = "inline";
        document.getElementById("temp-container").style.display = "none";
      };

    useEffect(() => {
        if (segmentedImage && !isStreaming) {
            setCapturedImage(segmentedImage);
        } else {
            console.log('APIResponseImgSrc is null and isStreaming is true');
        }
    }, [segmentedImage, isStreaming]);
    
    // Load APIResponseImgSrc onto the canvas when available
    useEffect(() => {
        if (segmentedImage && !isStreaming) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            const img = new Image();
            img.onload = () => {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            };
            img.src = segmentedImage;
        }
    }, [segmentedImage, isStreaming]);

    return (
        <div className="camera">
            <div id="temp-container" className="temp-container"></div> {/* Add this line */}
            <div id="webcam-container" className="webcam-container">
            {/* {capturedImage && <img alt="#" className="mainImg" src={capturedImage}/>} */}
            {!segmentedImage && !isStreaming && 
            <img className="mainImg" src={capturedImage} />}
            {segmentedImage && !isStreaming &&
            <img className="mainImg" src={segmentedImage} />}
            {!isCaptured && (
                    <Webcam ref={webcamRef} 
                        screenshotFormat="image/jpeg"
                        videoConstraints={{
                        ...videoConstraints,
                        facingMode,
                        }}
                        width="0"
                        height="0"
                    />
                    )}
                    <canvas ref={canvasRef}                   
                        id="canvas"
                        width="400"
                        height="300" />
                </div>
            
            <div className="controls" style={{ position: 'relative', top: '20px', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '400px' }}>
                <button onClick={switchCamera} style={{ margin: '0 10px', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#D3D3D3', width: '70px', height: '70px' }}>
                    <span style={{ color: 'black' }}>  Changer  </span>
                    <img src={switchIcon} alt="Switch" style={{ width: '32px', height: '32px', verticalAlign: 'middle', marginTop: '5px', backgroundColor: '#D3D3D3', borderColor: '#D3D3D3'}} />
                </button>
                <button onClick={captureImage} style={{ margin: '0 10px', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#D3D3D3', width: '70px', height: '70px' }} >
                    <span style={{ color: 'black' }}>Capturer</span>
                    <img src={captureIcon} alt="Capture" style={{ width: '32px', height: '32px', verticalAlign: 'middle', marginTop: '5px', backgroundColor: '#D3D3D3', borderColor: '#D3D3D3'}} />
                </button>
                <button onClick={startStream} style={{ margin: '0 10px', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#D3D3D3', width: '70px', height: '70px' }}>
                    <span style={{ color: 'black' }}>Diffuser</span>
                    <img src={streamIcon} alt="Stream" style={{ width: '32px', height: '32px', verticalAlign: 'middle', marginTop: '5px', backgroundColor: '#D3D3D3', borderColor: '#D3D3D3'}} />
                </button>
            </div>
        </div>
    );
};

export default Camera;