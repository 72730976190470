import React, { useState } from 'react';
import '../App.css';
import AlertMessage from './AlertMessage';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook
import { clearHairMask } from '../store';


const VisualConfirmation = ({ goToOverview, goToHairSegmentation, goToForms }) => {
  // Navigate to previous page
  const [showAlert, setShowAlert] = useState(false);
  const [selectedButtons, setSelectedButtons] = useState([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const dispatch = useDispatch(); // Initialize dispatch function
  const language = useSelector((state) => state.language);
  const translations = useSelector((state) => state.translationsData);

  const getTranslationText = (keyPath, data = translations, lang = language.toUpperCase()) => {
    const keys = keyPath.split('.');
    let result = data;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (Array.isArray(result)) {
        const found = result.find((item) => item.label === key);
        if (!found) {
          return undefined;
        }
        result = found;
      } else if (result[key] === undefined) {
        return undefined;
      } else {
        result = result[key];
      }

    }
    if (typeof result === 'object' && !Array.isArray(result) && result[lang] !== undefined) {
      return result[lang];
    }
    return result;
  };

  const visual_confirm = getTranslationText("visualConfirmation.questions.visual_confirmation.question");
  const visual_confirm_answers = getTranslationText("visualConfirmation.questions.visual_confirmation.answers");
  const warning = getTranslationText("visualConfirmation.errors.warning.message") || "You can select up to 3 options.";
  const confirmAlert = getTranslationText("visualConfirmation.confirmationAlert.alert.text") || "Are you sure you want to go back?";
  const confirmAlertText = getTranslationText("visualConfirmation.confirmationAlert.alert.subtext") || "Your progress will be lost.";

  const handleYes = () => {
    dispatch(clearHairMask());
    dispatch({ type:'ENABLE_HAIR_SEGMENTATION', payload:true});
    goToHairSegmentation();
  };

  // Navigate to next page
  const handleNo = () => {
    dispatch(clearHairMask());
    dispatch({ type:'ENABLE_HAIR_SEGMENTATION', payload:false});
    goToOverview();
  };

  const handlePrevClick = () => {
    setShowAlert(true);
  };

  const handleNextClick = () => {
    // setShowErrorAlert(false);
    setShowAlert(false);
  };


  return (
    <div className="form-financialProduct" style={{textAlign:'center'}}>
      <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
      <div className="content" style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh', // Full viewport height for vertical centering
        textAlign: 'center',
      }}>
        <h2 style={{fontSize: 'clamp(2.3rem, 2vw, 2.5rem)', width: 'auto', maxWidth: '600px', marginBottom: '20%', textAlign:'center'}}>{visual_confirm}</h2>
      </div>
      <div className='submit-container'>
        <div className="white-banner">
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '0px', width:'100%' }}>
            <button className="request-button-fixed" style={{ backgroundColor: 'black', color: 'white', marginTop: '50px', marginLeft: '-200px', marginRight: '0px', height:'5vh', width:'30vw', borderRadius: '25px', minHeight: '50px', maxHeight: '70px', minWidth: '100px', maxWidth: '150px', bottom: '10%'}} onClick={handleYes}>{visual_confirm_answers[0]}</button>
            <button className="request-button-fixed" style={{ backgroundColor: '#d49292', color: 'white', marginTop: '50px', marginLeft: '180px', height:'5vh', width:'30vw', borderRadius: '25px', minHeight: '50px', maxHeight: '70px', minWidth: '100px',maxWidth: '150px', bottom: '10%'}}  onClick={handleNo}>{visual_confirm_answers[1]}</button>
          </div>
        </div>
      </div>
      {showAlert && (
          <AlertMessage
          message={confirmAlert}
          subtext={confirmAlertText}
          onConfirm={() =>
              {
                setShowAlert(false);
                goToForms(6);
              }
              }
              onCancel={() => {
                setShowAlert(false);
            }}
          />
        )}
        {/* Navigation buttons */}
        <div className="footer" style={{ display: 'flex', alignItems: 'center' }}>
          <button onClick={handlePrevClick}>
            <span className="symbol">&#60;</span>
          </button>
          <button onClick={handleNextClick} style={{ background:'grey' }}>
            <span className="symbol">&#62;</span>
          </button>
        </div>
      </div>

    </div>
  );
};

export default VisualConfirmation;
