import React, { useState, useEffect } from 'react';
import '../App.css';
import { useDispatch, useSelector } from 'react-redux';

import { googleLogout } from '@react-oauth/google';
import { logoutProfile } from '../store';

import {addAgent} from './access_management/AddAgent';
import AlertMessage from './AlertMessage';


const Subscription = ({ goToStoreSelector, goToAuthorisation }) => {
  const userData = useSelector(state => state.agentData);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const language = useSelector(state => state.language);

  const translationsData = useSelector((state) => state.translationsData);

  const [formValues, setFormValues] = useState({
    agent_role: '',
    agent_reason: '',
    agent_country: '',
    agent_industry: '',
    agent_city: '',
    agent_company: '',
    referral_source: '',
    profession: '',
  });

  const trialExpired = useSelector(state => state.trialExpired);

  const environment  = process.env.REACT_APP_ENV
  const TRIAL_END_DATE = process.env.REACT_APP_TRIAL_END_DATE;
  const is_trial = process.env.REACT_APP_TRIAL_STATUS;
  const currentDate = new Date();

  const [showTrialAlert, setShowTrialAlert] = useState(false);

  const getTranslationText = (keyPath, data = translationsData, lang = language.toUpperCase()) => {
    const keys = keyPath.split('.'); // Split the keyPath into an array of keys

    // Traverse the data object using the keys
    let result = data;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      // Handle arrays (e.g., intro or questions)
      if (Array.isArray(result)) {
        const found = result.find((item) => item.label === key);
        if (!found) {
          return undefined; // Return undefined if the label is not found
        }
        result = found;
      } else if (result[key] === undefined) {
        return undefined; // Return undefined if the path is not found
      } else {
        result = result[key];
      }
    }

    // If the result is an object with language keys, return the value for the specified language
    if (typeof result === 'object' && !Array.isArray(result) && result[lang] !== undefined) {
      return result[lang];
    }

    // Otherwise, return the result as is
    return result;
  };

  const welcome = getTranslationText('subscription.intro.welcome.text');
  const agent_country_question = getTranslationText('subscription.questions.agent_country.question');
  const agent_role_question = getTranslationText('subscription.questions.agent_role.question');
  const agent_industry_question = getTranslationText('subscription.questions.agent_industry.question');
  const agent_city_question = getTranslationText('subscription.questions.agent_city.question');
  const agent_company_question = getTranslationText('subscription.questions.agent_company.question');
  const agent_reason_question = getTranslationText('subscription.questions.agent_reason.question');
  const agent_referral_source_question = getTranslationText('subscription.questions.referral_source.question');

  const agent_country_answers = getTranslationText('subscription.questions.agent_country.answers');
  const agent_role_answers = getTranslationText('subscription.questions.agent_role.answers');
  const agent_industry_answers = getTranslationText('subscription.questions.agent_industry.answers');
  const agent_reason_answers = getTranslationText('subscription.questions.agent_reason.answers');
  const agent_referral_source_answers = getTranslationText('subscription.questions.referral_source.answers');
  const select_option = getTranslationText('subscription.questions.select_option.text');
  const type_option = getTranslationText('subscription.questions.type_option.text');

  const back_button = getTranslationText('subscription.buttons.back.name');
  const submit_button = getTranslationText('subscription.buttons.submit.name');

  const trialExpiryAlertText = getTranslationText('authorization.trialExpiryAlert.text')
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prev => ({ ...prev, [name]: value }));

    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const updatedUserData = {
      ...userData,
      subscriptionDetail: formValues.agent_role,
      mainPurpose: formValues.agent_reason,
      country: formValues.agent_country,
      city: formValues.agent_city,
      industry: formValues.agent_industry,
      company: formValues.agent_company,
      referralSource: formValues.referral_source,
      status: 'Alive',
      subscriptionDate: new Date().toISOString(),
    };
  
    try {
      dispatch({ type: "SET_AGENT_DATA", payload: updatedUserData });
      const response = await addAgent(updatedUserData, environment, is_trial, dispatch); // Ensure async behavior
      
      console.log("Response from addAgent:", response);
      
      if (response) {
        console.log('trial status', trialExpired);
        if (trialExpired) {
          console.log("Trial expired, showing alert");
          setShowTrialAlert(true); // Show trial expiry alert
          return; // Stop further execution
        } else {
          console.log("Trial still active, proceeding to store selector");
          goToStoreSelector();
        }
      } else {
        console.error("Dispatch failed:");
      }
    } catch (error) {
      console.error("Dispatch failed:", error);
    }
  }; 

  const validateForm = () => {
    const validationErrors = {};
    if (!formValues.agent_role) validationErrors.agent_role = agent_role_question + ' is required.';
    if (!formValues.agent_country) validationErrors.agent_country = agent_country_question + ' is required.';
    if (!formValues.agent_city) validationErrors.agent_city = agent_city_question + ' is required.';
    if (!formValues.agent_reason) validationErrors.agent_reason = agent_reason_question + ' is required.';
    if (!formValues.referral_source) validationErrors.referral_source = agent_referral_source_question + ' is required.';
    return validationErrors;
  };

  const renderInputField = ({ question, label, type = 'text' }) => (
    <div className="form-group rounded-textbox">
      <label htmlFor={label}>{question}</label>
      <input
        type={type}
        id={label}
        name={label}
        value={formValues[label] || ''}
        onChange={handleChange}
        placeholder={type_option}
        required
        style={{ fontSize: '14px', padding: '10px', width: '100%', maxWidth: '300px', height: '100%', maxHeight: '50px' }}
      />
      {errors[label] && <div className="error" style={{ color: 'red', marginTop: '5px' }}>{errors[label]}</div>}
    </div>
  );

  const renderDropdown = ({ question, label, answers }) => (
    <div className="form-group rounded-textbox">
      <label htmlFor={label}>{question}</label>
      <div className="custom-dropdown">
        <select
          id={label}
          name={label}
          value={formValues[label]}
          onChange={handleChange}
          required
        >
          <option value="" disabled>
            {select_option}
          </option>
          {answers &&
            Object.entries(answers).map(([key, value]) => (
              <option key={key} value={value}>
                {value}
              </option>
            ))}
        </select>
      </div>
      {errors[label] && <div className="error" style={{ color: 'red', marginTop: '5px' }}>{errors[label]}</div>}
    </div>
  );

  return (
    <div className="welcome-container">
      <h1>
        {welcome} {userData.agentFullName}! 🎉
      </h1>

      <form onSubmit={handleSubmit} className="welcome-form">
        {renderDropdown({ question: agent_country_question, label: 'agent_country', answers: agent_country_answers })}
        {renderInputField({ question: agent_city_question, label: 'agent_city' })}
        {renderDropdown({ question: agent_role_question, label: 'agent_role', answers: agent_role_answers })}
        {renderDropdown({ question: agent_industry_question, label: 'agent_industry', answers: agent_industry_answers })}
        {renderInputField({ question: agent_company_question, label: 'agent_company' })}
        {renderDropdown({ question: agent_reason_question, label: 'agent_reason', answers: agent_reason_answers })}
        {renderDropdown({ question: agent_referral_source_question, label: 'referral_source', answers: agent_referral_source_answers })}

        <div className="form-actions">
          <button
            type="button"
            className="back-button"
            onClick={() => {
              googleLogout();
              logoutProfile();
              goToAuthorisation();
            }}
          >
            {back_button}
          </button>
          <button type="submit" className="continue-button">
            {submit_button}
          </button>
        </div>
      </form>
      {showTrialAlert && (
        <AlertMessage
          message={trialExpiryAlertText}
          onConfirm={async () => {
            setShowTrialAlert(false);
            goToAuthorisation();
          }}
        />
      )}
    </div>
  );
};

export default Subscription;