import axios from 'axios';
import translations from './translationsDB.json'; // Fallback data

// Cache to avoid unnecessary API calls
const cache = new Map();


const fetchLanguage = async (key = "", language = 'EN') => {
  try {
    if (!key) {
      return translations; // Return full JSON if no key provided
    }

    const cacheKey = `${key}-${language}`;
    if (cache.has(cacheKey)) {
      return cache.get(cacheKey); // Return cached data
    }

    // Fetch specific translation from MongoDB
    const response = await axios.get(`https://your-mongo-api.com/translations`, {
      params: { key, language }
    });

    const data = response.data || {};
    cache.set(cacheKey, data); // Store result in cache
    return data;
  } catch (error) {
    console.error("Error fetching translation:", error);
    return {}; // Return empty object on error
  }
};

export default fetchLanguage;