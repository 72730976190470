import React, { useState } from 'react';
import '../../App.css';
import { useSelector, useDispatch } from 'react-redux';
import MultiStepMenu from '../MultiStepMenu';
import AlertMessage from '../AlertMessage';

const HairChallenges = ({ goToNextStep, goToPrevStep }) => {
  const [selectedButtons, setSelectedButtons] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [selectionLimitAlert, setSelectionLimitAlert] = useState(false);
  const dispatch = useDispatch();
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const questionnaire = useSelector(state => state.questionnaire); // Get language from Redux store

  const language = useSelector((state) => state.language);
  const translations = useSelector((state) => state.translationsData);

  const getTranslationText = (keyPath, data = translations, lang = language.toUpperCase()) => {
    const keys = keyPath.split('.');
    let result = data;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (Array.isArray(result)) {
        const found = result.find((item) => item.label === key);
        if (!found) {
          return undefined;
        }
        result = found;
      } else if (result[key] === undefined) {
        return undefined;
      } else {
        result = result[key];
      }

    }
    if (typeof result === 'object' && !Array.isArray(result) && result[lang] !== undefined) {
      return result[lang];
    }
    return result;
  };

  const hair_challenges = getTranslationText("hairChallenges.questions.hair_challenges.question");
  const warning = getTranslationText("hairChallenges.errors.warning.message") || "You can select up to 3 options.";
  const termsAndConditionsConsent = getTranslationText("hairChallenges.termsAndConditionsConsent.terms.text") || "Please agree to the terms and conditions.";
  const confirmAlert = getTranslationText("hairChallenges.confirmationAlert.alert.text") || "Are you sure you want to go back?";
  const confirmAlertText = getTranslationText("hairChallenges.confirmationAlert.alert.subtext") || "Your progress will be lost.";


  const handlePrevClick = () => {
    setShowAlert(true);
};

  const handleNextClick = () => {
    if (selectedButtons.length > 0) {
      dispatch({ type: 'SAVED_HAIR_CHALLENGES', payload: selectedButtons });
      goToNextStep();
    } else {
      setShowErrorAlert(true);
    }
  };

  const handleButtonClick = (buttonText) => {
    if (selectedButtons.includes(buttonText)) {
      setSelectedButtons(selectedButtons.filter(button => button !== buttonText));
      setSelectionLimitAlert(false);
    } else {
      if (selectedButtons.length < 3) {
        setSelectedButtons([...selectedButtons, buttonText]);
        setSelectionLimitAlert(false);
      } else {
        setSelectionLimitAlert(true);
      }
    }
  };

  return (
    <div className="container">
      <div className="fixed-header">
        <MultiStepMenu step={5} numberOfSteps={6} />
        <h2 style={{ position: 'sticky', backgroundColor: 'white', padding: '10px', zIndex: 1, marginTop: '10%', fontSize: 'clamp(1.5rem, 2vw, 2.5rem)', textAlign: 'center' }}>
        {hair_challenges}
          </h2>
      </div>
      <div className="form" style={{marginTop: '25%'}}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', maxHeight: '500px', overflowY: 'auto',  scrollbarWidth: 'none',  msOverflowStyle: 'none'  }}>
            {questionnaire['q004'].map(buttonText => (
                <button
                  className="submit-button"
                  key={buttonText}
                  style={{
                    margin: '5%',
                    backgroundColor: selectedButtons.includes(buttonText) ? '#d49292' : 'black',
                    // width: '80%',
                    height: '10%',
                    width: '250px'
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleButtonClick(buttonText);
                  }}
                >
                  <span>{buttonText}</span>
                </button>
              ))}
          </div>
          {selectionLimitAlert && (
            <p style={{ color: 'red' }}>
              {warning}
            </p>
          )}
        </div>

        {showAlert && (
          <AlertMessage
          message={confirmAlert}
          subtext={confirmAlertText}
          onConfirm={() =>
              {
                setShowAlert(false);
                goToPrevStep();
              }
              }
              onCancel={() => {
                setShowAlert(false);
            }}
          />
        )}

        {showErrorAlert && (
        <AlertMessage
          message={termsAndConditionsConsent}
          onConfirm={() =>
          {
            setShowErrorAlert(false);
          }
          }
        />
      )}

        {/* Navigation buttons */}
        <div className="footer" style={{ display: 'flex', alignItems: 'center' }}>
          <button onClick={handlePrevClick}>
            <span className="symbol">&#60;</span>
          </button>
          <button onClick={handleNextClick}>
            <span className="symbol">&#62;</span>
          </button>
        </div>
      </div>
  );
};

export default HairChallenges;